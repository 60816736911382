import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FileUploader, UploadFile } from '@davies-group/document-upload-lib';
import { Box } from '@mui/material';

import { store } from '../../index';
import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import { updateAssessmentElement } from '../../state/assessment/actions';
import InputBase from '../inputs/InputBase';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {
	updateAssessmentElement: updateAssessmentElement
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function DocumentUploadInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const assessmentState = store.getState().assessment;

	const [assessmentId] = React.useState<string>(assessmentState.assessment?.assessmentId ?? '');
	const [files, setFiles] = React.useState<UploadFile[]>([]);
	const [fileIds, setFileIds] = React.useState<string[]>(files.map(file => file.id));

	React.useEffect(() =>
	{
		const existingFileValues = AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'UploadFiles');
		const existingFiles = existingFileValues.map(file => JSON.parse(file) as UploadFile);
		setFiles(existingFiles)
	}, []);

	React.useEffect(() =>
	{
		const newFileIds = files.map(file => file.id);
		setFileIds(newFileIds);

		//Store the file ids as the answer
		AssessmentLocalStorageManager.setAnswerValues(assessmentId ?? '', props.question.id, newFileIds)

		//Store the whole files for display
		const fileValues = files.map(file => JSON.stringify(file));
		AssessmentLocalStorageManager.setAnswerValues(assessmentId ?? '', props.question.id, fileValues, 'UploadFiles')

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	}, [files]);

	return <InputBase
		onValidatorResultChanged={props.onValidatorResultChanged}
		validator={{
			validatorRules: questionManager.getQuestionValidatorRules(props.question),
			config: props.validatorConfig
		}}
		value={fileIds}
		answerValue={fileIds}>
		<Box width={1}>
			<FileUploader
				files={files}
				maxTotalFiles={10}
				onFileUploaded={(newFile) => setFiles((prev) => [...prev, newFile])}
				onFileRemoved={(fileId) => setFiles((prev) => prev.filter(i => i.id !== fileId))}
				onFileRemoveFailed={(i) => console.log('Failed to remove file: ' + i)} />
		</Box>
	</InputBase>
}

export default connector(DocumentUploadInput)
