import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TypographyVariant, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IAssessmentElement from '../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../models/IValidator';
import Common from '../plugins/Common';
import QuestionManager from '../plugins/QuestionManager';
import { RootState } from '../state';

import BankAccountInput from './assessmentQuestions/BankAccountInput';
import ButtonCircleInput from './assessmentQuestions/ButtonCircleInput';
import ButtonSquareInput from './assessmentQuestions/ButtonSquareInput';
import CheckListInput from './assessmentQuestions/CheckListInput';
import DateInput from './assessmentQuestions/DateInput';
import DocumentUploadInput from './assessmentQuestions/DocumentUploadInput';
import DropdownInput from './assessmentQuestions/DropdownInput';
import LookupDataInput from './assessmentQuestions/LookupDataInput';
import NumberInput from './assessmentQuestions/NumberInput';
import PolicyInput from './assessmentQuestions/PolicyInput';
import RadioInput from './assessmentQuestions/RadioInput';
import TextAreaInput from './assessmentQuestions/TextAreaInput';
import TextInput from './assessmentQuestions/TextInput';
import TimeInput  from './assessmentQuestions/TimeInput';
import VehicleDetailsInput from './assessmentQuestions/VehicleDetailsInput';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux
{
	element: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}
export function AssessmentElement(props: IProps)
{
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			{(props.element.isAlwaysShown
				|| new QuestionManager().showElement(props.element.showConditionSource ?? '', props.element.showConditionValue ?? '')) &&
				<Box py={props.element.type === 'PLAIN_TEXT' ? 0.5 : 2}>
					<Box pb={props.element.type === 'PLAIN_TEXT' ? 0 : 1}>
						{
							(props.element.type === 'HEADING') &&
							<Typography align="left" variant="h2">
								<div dangerouslySetInnerHTML={{ __html: Common.unescapeHtml(props.element.heading ?? '') }} />
							</Typography>
						}
						{
							(props.element.type === 'PLAIN_TEXT') &&
							<Typography align="left" variant={props.element.metadata?.textVariant as TypographyVariant ?? 'body1'}>
								<div dangerouslySetInnerHTML={{ __html: Common.unescapeHtml(props.element.heading ?? '') }} />
							</Typography>
						}
						{
							(props.element.type !== 'HEADING' && props.element.type !== 'PLAIN_TEXT') &&
							<>
								<Typography align="left" variant="h6">
									<div dangerouslySetInnerHTML={{ __html: Common.unescapeHtml(props.element.heading ?? '') }} />
								</Typography>
								<Typography align="left" variant="subtitle1" style={{ whiteSpace: 'pre-line' }}>
									<div dangerouslySetInnerHTML={{ __html: Common.unescapeHtml(props.element.subHeading ?? '') }} />
								</Typography>
							</>
						}
					</Box>
					<Box data-testid={props.element.referenceId}>
						{(() =>
						{
							switch (props.element.type)
							{
								case 'TEXTBOX':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<TextInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'TEXTAREA':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<TextAreaInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'POLICY_POSTCODE':
								case 'POLICY_DOB':
								case 'POLICY_EMAIL':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<PolicyInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'YESNO':
									return <Box display="flex" alignItems="flex-start">
										<ButtonCircleInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'BUTTON':
								case 'AGREEDISAGREE':
									return <Box display="flex" alignItems="flex-start">
										<ButtonSquareInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'DATE':
									return <Box width={isSmallScreen ? 1 : 1 / 2}>
										<DateInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'CUSTOMER_CONNECT':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<RadioInput
											question={props.element}
											defaultValue={'true'}//Customer Connect should be true by default
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'RADIO':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<RadioInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'DROPDOWN':
									return <Box display="flex" alignItems="flex-start" width={isSmallScreen ? 1 : 1 / 2}>
										<DropdownInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'CHECKLIST':
									return <Box display="flex" alignItems="flex-start" width={isSmallScreen ? 1 : 1 / 2}>
										<CheckListInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'NUMERIC':
									return <Box display="flex" alignItems="flex-start" width={isSmallScreen ? 1 : 1 / 2}>
										<NumberInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'LOOKUPDATA':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<LookupDataInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'VEHICLEVRMLOOKUP':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<VehicleDetailsInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation}
										/>
									</Box>
								case 'DOCUMENT_UPLOAD':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<DocumentUploadInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'BANK_ACCOUNT':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<BankAccountInput
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation} />
									</Box>
								case 'TIME':
									return <Box display="flex" alignItems="flex-start" width={1}>
										<TimeInput 
											question={props.element}
											validatorConfig={props.validatorConfig}
											onValidatorResultChanged={props.onValidatorResultChanged}
											onResetValidation={props.onResetValidation}/>
									</Box>
								default:
									return null;
							}
						})()}
					</Box>
				</Box>
			}
		</>
	)
}

export default connector(AssessmentElement)
