import * as React from 'react';
import { Box, Typography,useMediaQuery, useTheme } from '@mui/material';

interface IProps
{
	index: number,
	pageName: string
}

export default function ProgressBarLabel(props: IProps)
{
	const [strIndex, setStrIndex] = React.useState<string>('');
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	React.useEffect(() =>
	{
		let strIndex = (props.index +1).toString();
		if(strIndex.length == 1)
			strIndex = '0' + strIndex

		setStrIndex(strIndex)
	}, [props.index]);

	return (
		<Box alignItems="flex-start" justifyContent="center" display="flex" alignContent="flex-start" flexWrap="wrap">
			<Typography sx={{fontSize:'0.7em'}} align="center" variant="subtitle1">
				<strong>{strIndex}</strong> {!isSmallScreen && (' ' + props.pageName)}
			</Typography>
		</Box>
	)
}
