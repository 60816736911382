import { Schema } from 'jsonschema';
import moment from 'moment';

import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorRule } from '../../models/IValidator';

export const ValidationRuleHelper =
{
	required: (fieldName: string) : IValidatorRule =>
		({
			validationRule: 'REQUIRED',
			errorMessage: fieldName + ' is required'
		}),

	maxLength: (fieldName: string, maxLength: number) : IValidatorRule =>
		({
			validationRule: 'MAX_STRING_LENGTH',
			validationRuleParam: maxLength.toString(),
			errorMessage: fieldName + ' must be less than or equal to ' + maxLength + ' characters'
		}),

	minLength: (fieldName: string, minLength: number) : IValidatorRule =>
		({
			validationRule: 'MIN_STRING_LENGTH',
			validationRuleParam: minLength.toString(),
			errorMessage: fieldName + ' must be greater than or equal to ' + minLength + ' characters'
		}),

	isNumber: (fieldName: string) : IValidatorRule =>
		({
			validationRule: 'IS_NUMBER',
			errorMessage: fieldName + ' must be a valid number'
		}),

	maxNumber: (fieldName: string, maxNumber: number) : IValidatorRule =>
		({
			validationRule: 'MAX_NUMBER',
			validationRuleParam: maxNumber.toString(),
			errorMessage: fieldName + ' must be less than or equal to ' + maxNumber
		}),

	minNumber: (fieldName: string, minNumber: number) : IValidatorRule =>
		({
			validationRule: 'MIN_NUMBER',
			validationRuleParam: minNumber.toString(),
			errorMessage: fieldName + ' must be greater than or equal to ' + minNumber
		}),

	maxDate: (fieldName: string, maxDate: Date) : IValidatorRule =>
		({
			validationRule: 'MAX_DATE',
			validationRuleParam: moment(maxDate).format('YYYY-MM-DD'),
			errorMessage: fieldName + ' must be less than or equal to ' + moment(maxDate).format('DD/MM/YYYY')
		}),

	minDate: (fieldName: string, minDate: Date) : IValidatorRule =>
		({
			validationRule: 'MIN_DATE',
			validationRuleParam: moment(minDate).format('YYYY-MM-DD'),
			errorMessage: fieldName + ' must be greater than or equal to ' + moment(minDate).format('DD/MM/YYYY')
		}),

	pastDate: (fieldName: string, offsetDays: number) : IValidatorRule =>
	{
		let offsetMessage = '';
		const absoluteOffset = Math.abs(offsetDays);
		if(offsetDays < 0)
			offsetMessage = `${absoluteOffset} day${absoluteOffset > 1 ? 's' : ''} before`
		else if(offsetDays > 0)
			offsetMessage = `${absoluteOffset} day${absoluteOffset > 1 ? 's' : ''} after`

		return {
			validationRule: 'PAST_DATE',
			validationRuleParam: offsetDays.toString(),
			errorMessage: `${fieldName} must be less than or equal to ${offsetMessage} today\'s date`
		}
	},

	futureDate: (fieldName: string, offsetDays: number) : IValidatorRule =>
	{
		let offsetMessage = '';
		const absoluteOffset = Math.abs(offsetDays);
		if(offsetDays < 0)
			offsetMessage = `${absoluteOffset} day${absoluteOffset > 1 ? 's' : ''} before`
		else if(offsetDays > 0)
			offsetMessage = `${absoluteOffset} day${absoluteOffset > 1 ? 's' : ''} after`

		return {
			validationRule: 'FUTURE_DATE',
			validationRuleParam: offsetDays.toString(),
			errorMessage: `${fieldName} must be greater than or equal to ${offsetMessage} today\'s date`
		}
	},

	isEmail: (fieldName: string) : IValidatorRule =>
		({
			validationRule: 'IS_EMAIL',
			errorMessage: fieldName + ' is not a valid Email Address'
		}),

	question: (question: IAssessmentElement) : IValidatorRule =>
		({
			validationRule: 'QUESTION',
			validationRuleParam: JSON.stringify(question),
			errorMessage: 'Answer has failed Question Validation'
		}),

	schema: (validationSchema: Schema) : IValidatorRule =>
		({
			validationRule: 'SCHEMA',
			validationRuleParam: JSON.stringify(validationSchema),
			errorMessage: 'Answer has failed Schema Validation'
		}),

	isDate: (fieldName: string) : IValidatorRule =>
		({
			validationRule: 'SCHEMA',
			validationRuleParam: JSON.stringify({
				properties: {
					value: {
						type: 'array',
						items: {
							type: 'string',
							format: 'date'
						}
					}
				}
			} as Schema),
			errorMessage: fieldName + ' is not a valid Date'
		}),

	isGuid: (fieldName: string) : IValidatorRule =>
		({
			validationRule: 'IS_GUID',
			errorMessage: fieldName + ' must be a valid guid'
		}),

	isValidBankAccount: () : IValidatorRule =>
		({
			validationRule: 'IS_VALID_BANK_ACCOUNT',
			errorMessage: ''
		})
}