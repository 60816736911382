import IAnswer from '../../models/IAnswer';
import IAssessment from '../../models/IAssessment';
import IAssessmentElement from '../../models/IAssessmentElement';
import IAssessmentPage from '../../models/IAssessmentPage';

export interface IAssessmentState
{
	assessment: IAssessment | null,
	page: IAssessmentPage | null,
	pageAnswers: IAnswer[],
	pageActionsComplete: boolean,
	pageIndex: number,
	totalPages: number,
	isFirstPage: boolean,
	isLastPage: boolean,
}

export const SET_ASSESSMENT = 'SET_ASSESSMENT'
export const SET_PAGE = 'SET_PAGE'
export const SET_ANSWER = 'SET_ANSWER'
export const UPDATE_ASSESSMENT_ELEMENT = 'UPDATE_ASSESSMENT_ELEMENT'

interface SetAssessmentaction
{
	type: typeof SET_ASSESSMENT,
	payload: IAssessment | null
}

interface SetPageAction
{
	type: typeof SET_PAGE,
	payload: IAssessmentPage | null
}

interface SetAnswerAction
{
	type: typeof SET_ANSWER,
	payload: IAnswer
}

interface UpdateAssessmentElement
{
	type: typeof UPDATE_ASSESSMENT_ELEMENT,
	payload: IAssessmentElement
}

export type AssessmentActionTypes = SetAssessmentaction | SetPageAction | SetAnswerAction | UpdateAssessmentElement