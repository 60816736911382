import React from 'react';
import { Checkbox as StyledCheckbox } from '@davies-group/white-label-ui/ui'
import { CheckboxProps, FormControlLabel, FormGroup} from '@mui/material';

import IOption from '../../models/IOption';
import { IValidator, IValidatorResult } from '../../models/IValidator';

import InputBase from './InputBase';

interface IProps
{
	options: IOption[],
	onOptionCheck(optionValue: string, checked: boolean): void,
	isOptionSelected(optionValue: string): boolean,
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult) : void,
	answerValue?: any[],
	outerLabel?: string,
	helpText?: string
}

export default function CheckList(props: CheckboxProps & IProps)
{
	const { validator, options, onValidatorResultChanged, isOptionSelected, onOptionCheck, answerValue, outerLabel, helpText, ...rest } = props

	const children = <FormGroup>
		{options.map((option, index) =>
			<FormControlLabel
				key={index}
				value={option.value}
				label={option.text}
				control=
					{
						<StyledCheckbox
							{...rest}
							data-testid={option.value}
							checked={isOptionSelected(option.value)}
							onChange={(event) => onOptionCheck(option.value, event.target.checked)}
						/>
					}
			/>)}
	</FormGroup>

	return (
		<InputBase
			outerLabel={outerLabel}
			helpText={helpText}
			onValidatorResultChanged={onValidatorResultChanged}
			validator={validator}
			value={props.value}
			answerValue={answerValue}>
			{children}
		</InputBase>
	)
}
