import React from 'react';
import { Select as StyledSelect } from '@davies-group/white-label-ui/ui'
import { ListSubheader, MenuItem, SelectProps, Tooltip, Typography} from '@mui/material';

import IOption from '../../models/IOption';
import { IValidator, IValidatorResult } from '../../models/IValidator';

import InputBase from './InputBase';

interface IProps
{
	options: IOption[],
	groupOptions?: boolean,
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult) : void,
	answerValue?: any[],
	outerLabel?: string,
	helpText?: string
}

export default function DropDown(props: SelectProps & IProps)
{
	const { validator, options, groupOptions, onValidatorResultChanged, answerValue, outerLabel, helpText, ...rest } = props

	const GetOptionDisplay = (option: IOption, index: number): JSX.Element =>
	{
		if(groupOptions && option.isGroupHeader)
			return <ListSubheader disableSticky data-testid={option.text}>{option.text}</ListSubheader>
		else if(option.tooltipText)
			return <MenuItem key={index} value={option.value} data-testid={option.value}><Tooltip placement="right-end" title={option.tooltipText}><Typography>{option.text}</Typography></Tooltip></MenuItem>;
		else
			return <MenuItem key={index} value={option.value} data-testid={option.value}><Typography>{option.text}</Typography></MenuItem>;
	}

	const children = (
		<StyledSelect
			{...rest}
			variant="outlined"
			fullWidth={true}>
			{options.map((option, index) => GetOptionDisplay(option, index))}
		</StyledSelect>
	)

	//Disable OnBlur validation for Select Component
	const doOnBlur = () => false

	return (
		<InputBase
			outerLabel={outerLabel}
			helpText={helpText}
			onValidatorResultChanged={onValidatorResultChanged}
			validator={validator}
			value={props.value}
			answerValue={answerValue}
			doOnBlur={doOnBlur}>
			{children}
		</InputBase>
	)
}
