import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ButtonCTA } from '@davies-group/white-label-ui/ui';
import { Box, Tooltip } from '@mui/material';

import { store } from '../../index';
import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import ApiLookUpManager from '../../plugins/ApiLookupManager';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import QuestionManager from '../../plugins/QuestionManager';
import { ValidationRuleHelper } from '../../plugins/Validation/ValidationRuleHelper';
import { RootState } from '../../state';
import TextField from '../inputs/TextField';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

const answers: string[] = [];

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function VehicleDetailsInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const assessmentState = store.getState().assessment;

	const [assessmentId] = React.useState<string>(assessmentState.assessment?.assessmentId ?? '');
	const [hasFocus, setHasFocus] = React.useState<boolean>(false);
	const [vrmValue, setVrmValue] = React.useState<string>('');
	const [vehicleReg, setVehicleReg] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleRegistration')[0]);
	const [vehicleMake, setVehicleMake] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleMake')[0]);
	const [vehicleColour, setVehicleColour] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleColour')[0]);
	const [vehicleEngineSize, setVehicleEngineSize] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleEngineSize')[0]);
	const [vehicleModel, setVehicleModel] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleModel')[0]);
	const [vehicleType, setVehicleType] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleType')[0]);
	const [isVehicleLoaded, setIsVehicleLoaded] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'VehicleLoaded')[0]);

	const sanitiseVRM = (rawVRM: string): string =>
	{
		//Remove all non alphanumeric characters from VRM
		const sanitisedVRMValue= rawVRM.replace(/[^A-Za-z0-9]/g, '');
		return sanitisedVRMValue;
	}

	const onVrmChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const vrmValue = e.target.value;
		const sanitisedVRMValue= sanitiseVRM(vrmValue);
		setVrmValue(sanitisedVRMValue);
	};

	const setAnswerValues = (questionId: string, answerValues: string[], fieldName: string) =>
	{
		AssessmentLocalStorageManager.setAnswerValues(assessmentState.assessment?.assessmentId ?? '', questionId, answerValues, fieldName)
	};

	const onRegChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		const sanitisedVRMValue= sanitiseVRM(value);
		setVehicleReg(sanitisedVRMValue)
		setAnswerValues(props.question.id,[sanitisedVRMValue], 'VehicleRegistration')
		if (!hasFocus)
			updateStoredAnswer([value])
	};

	const onMakeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setVehicleMake(value)
		setAnswerValues(props.question.id,[e.target.value], 'VehicleMake')
		if (!hasFocus)
			updateStoredAnswer([value])
	};

	const onColourChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setVehicleColour(value)
		setAnswerValues(props.question.id,[e.target.value], 'VehicleColour')
		if (!hasFocus)
			updateStoredAnswer([value])
	};

	const onEngineChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setVehicleEngineSize(value)
		setAnswerValues(props.question.id,[e.target.value], 'VehicleEngineSize')
		if (!hasFocus)
			updateStoredAnswer([value])
	};

	const onModelChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setVehicleModel(value)
		setAnswerValues(props.question.id,[e.target.value], 'VehicleModel')
		if (!hasFocus)
			updateStoredAnswer([value])
	};

	const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setVehicleType(value)
		setAnswerValues(props.question.id,[e.target.value], 'VehicleType')
		if (!hasFocus)
			updateStoredAnswer([value])
	};

	const onBlur = () =>
	{
		answers[0] = vehicleReg;
		answers[1] = vehicleMake;
		answers[2] = vehicleColour;
		answers[3] = vehicleEngineSize;
		answers[4] = vehicleModel;
		answers[5] = vehicleType;
		updateStoredAnswer(answers)
		setHasFocus(false)
	};

	const updateStoredAnswer = (values: string[]) =>
	{
		questionManager.updateAnswerValue(props.question.id, values);
		if (props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	const getVehicleDetails = () =>
	{
		const vehicleVrm  = vrmValue;
		answers.length = 0;
		setAnswerValues(props.question.id,['false'], 'VehicleLoaded');
		setIsVehicleLoaded('false');

		(async () =>
	   {

			await new ApiLookUpManager().getVehicleByVrm(vehicleVrm)
				.then(result =>
				{
					if(result)
					{

						answers.push(result.vehicleRegistration);
						setVehicleReg(answers[0])
						setAnswerValues(props.question.id,[result.vehicleRegistration], 'VehicleRegistration')

						answers.push(result.vehicleMake);
						setVehicleMake(answers[1])
						setAnswerValues(props.question.id,[result.vehicleMake], 'VehicleMake')

						answers.push(result.vehicleColour);
						setVehicleColour(answers[2])
						setAnswerValues(props.question.id,[result.vehicleColour], 'VehicleColour')

						answers.push(result.vehicleEngineSize);
						setVehicleEngineSize(answers[3])
						setAnswerValues(props.question.id,[result.vehicleEngineSize], 'VehicleEngineSize')

						answers.push(result.vehicleModel);
						setVehicleModel(answers[4])
						setAnswerValues(props.question.id,[result.vehicleModel], 'VehicleModel')

						switch(result.vehicleType)
						{
							case '1':
								answers.push('Car');
								break;
							case '2':
								answers.push('Motorcycle');
								break;
							case '3':
								answers.push('Bus');
								break;
							case '4':
								answers.push('HGV');
								break;
							case '5':
								answers.push('LGV');
								break;
							case '6':
							    answers.push('Specialist');
								break;
							case '99':
								answers.push('99');
								break;
						}

						setVehicleType(answers[5])
						setAnswerValues(props.question.id,[result.vehicleType], 'VehicleType')

						updateStoredAnswer(answers)//update the answer to this question. Answer is 4 entries for vehicle details

					}

				})

			setAnswerValues(props.question.id,['true'], 'VehicleLoaded')
			setIsVehicleLoaded('true')//loaded vehicle details

		})();

	};

	const renderVrmSearchInputs = () => (
		<Box display="flex" flexDirection="column" >
			<Box>
				<TextField
					onValidatorResultChanged={props.onValidatorResultChanged}
					value={vrmValue}
					onFocus={() => setHasFocus(true)}
					onChange={onVrmChange} />
			</Box>
			<Box>
				<ButtonCTA
					disabled={vrmValue.length === 0}
					fullWidth={true}
					size="medium"
					onClick={getVehicleDetails}>
					Search Registration
				</ButtonCTA>
			</Box>
		</Box>
	);

	const renderVehicleInputs = () => (
		<Box marginTop="20px">
			<Box>
				<TextField
					data-testid="vehicleRegistration"
					outerLabel="Vehicle Registration"
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [ValidationRuleHelper.required('Vehicle Registration')],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each vehicle input is validated uniquely
						}
					}}
					value={vehicleReg ?? ''}
					onBlur={onBlur}
					onFocus={() => setHasFocus(true)}
					onChange={onRegChange} />
			</Box>
			<Box>
				<TextField
					data-testid="vehicleMake"
					outerLabel="Vehicle Make"
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [ValidationRuleHelper.required('Vehicle Make')],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each vehicle input is validated uniquely
						}
					}}
					value={vehicleMake ?? ''}
					onBlur={onBlur}
					onFocus={() => setHasFocus(true)}
					onChange={onMakeChange} />
			</Box>
			<Box>
				<TextField
					data-testid="vehicleColour"
					outerLabel="Vehicle Colour"
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [ValidationRuleHelper.required('Vehicle Colour')],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each vehicle input is validated uniquely
						}
					}}
					value={vehicleColour ?? ''}
					onBlur={onBlur}
					onFocus={() => setHasFocus(true)}
					onChange={onColourChange} />
			</Box>
			<Box>
				<TextField
					data-testid="vehicleEngineSize"
					outerLabel="Vehicle Engine Size"
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [ValidationRuleHelper.required('Vehicle Engine Size')],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each vehicle input is validated uniquely
						}
					}}
					value={vehicleEngineSize ?? ''}
					onBlur={onBlur}
					onFocus={() => setHasFocus(true)}
					onChange={onEngineChange} />
			</Box>
			<Box>
				<TextField
					data-testid="vehicleModel"
					outerLabel="Vehicle Model"
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [ValidationRuleHelper.required('Vehicle Model')],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each vehicle input is validated uniquely
						}
					}}
					value={vehicleModel ?? ''}
					onBlur={onBlur}
					onFocus={() => setHasFocus(true)}
					onChange={onModelChange} />
			</Box>
			<Tooltip title="Vehicle type should be Car, Motorcycle, Bus, HGV, LGV, Specialist or Other">
				<Box>
					<TextField
						data-testid="vehicleType"
						outerLabel="Vehicle Type"
						onValidatorResultChanged={props.onValidatorResultChanged}
						validator={{
							validatorRules: [ValidationRuleHelper.required('Vehicle Type')],
							config: {
								...props.validatorConfig,
								validationFieldGuidOverride: undefined //remove override to ensure each vehicle input is validated uniquely
							}
						}}
						value={vehicleType ?? ''}
						onBlur={onBlur}
						onFocus={() => setHasFocus(true)}
						onChange={onTypeChange} />
				</Box>
			</Tooltip>
		</Box>
	);

	return (
		<Box>
			{renderVrmSearchInputs()}
			{isVehicleLoaded === 'true' ? (
				renderVehicleInputs()
			) : null}
		</Box>
	);

}

export default connector(VehicleDetailsInput);