import axios, { AxiosResponse } from 'axios';

import IVehicle from '../models/IVehicle';

export default class ApiLookupService
{

	public getVehicleByVrm(vehicleVrm: string): Promise<IVehicle>
	{
		const url = `${window.__appSettings.apiEndpoints.apiLookup}/vehicle?vehicleVrm=${encodeURIComponent(vehicleVrm)}`;

		return axios.get<IVehicle>(url)
			.then(response =>
			{
				this.checkResponse(url, response);
				return response.data;
			});
	}

	private checkResponse<T>(url: string, response: AxiosResponse<T>): boolean
	{
		if (response.status >= 200 && response.status <= 299)
		{
			console.info(`Call to ${url} was successful`);
			return true;
		}

		throw new Error(`Failed to call ${url} (Status: ${response.status})`);
	}

}