import React from 'react';
import { TextField as StyledTextField } from '@davies-group/white-label-ui/ui'
import { CircularProgress, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import IOption from '../../models/IOption';
import { IValidator, IValidatorResult } from '../../models/IValidator';

import InputBase from './InputBase';

interface IProps
{
	value: IOption | null,
	options: IOption[],
	onValueChange(value: IOption | null) : void,
	onInputChange(value: string) : void,
	isLoading: boolean,
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult) : void,
	answerValue?: any[],
	outerLabel?: string,
	helpText?: string
}

export default function SmartSearch(props: IProps)
{
	const [inputValue, setInputValue] = React.useState('');

	React.useEffect(() =>
	{
		//Populate field with existing value
		setInputValue(props.value?.text ?? '');
	}, [props.value]);

	const onInputChange = (newInputValue: string) =>
	{
		setInputValue(newInputValue);
		props.onInputChange(newInputValue);
	};

	const onValueChange = (newValue: IOption | null) =>
	{
		onInputChange(newValue?.text ?? '')
		props.onValueChange(newValue)
	};

	//Disable filtering as this is handled by refreshing Options on Input Change
	const filterOptions = (options :IOption[]) => options

	const children = (
		<Autocomplete
			noOptionsText={(inputValue?.length ?? 0) == 0 ? 'Please enter search criteria' : 'No options found'}
			fullWidth={true}
			filterOptions={filterOptions}
			value={props.value}
			onChange={(_: any, newValue: IOption | null) => onValueChange(newValue)}
			inputValue={inputValue}
			onInputChange={(event: React.ChangeEvent<unknown>, newInputValue: string, reason: string) =>
			{
				if (reason === 'input')
					onInputChange(newInputValue)
			}}
			isOptionEqualToValue={(option, value) => option.value === value.value}
			getOptionLabel={(option) => option.text}
			options={props.options}
			renderInput={(params) => (
				<StyledTextField
					{...params}
					data-testid="smartSearch"
					label="Search..."
					fullWidth={true}
					InputProps={{
						...params.InputProps,
						endAdornment: <>{props.isLoading && <InputAdornment position="end"><CircularProgress color="inherit" size={20} /></InputAdornment>} {params.InputProps.endAdornment}</>
					}}
				/>)}
		/>
	)

	return (
		<InputBase
			outerLabel={props.outerLabel}
			helpText={props.helpText}
			onValidatorResultChanged={props.onValidatorResultChanged}
			validator={props.validator}
			value={props.value}
			answerValue={props.answerValue}>
			{children}
		</InputBase>
	)
}
