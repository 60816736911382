import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import AssessmentLoadingPage from './containers/AssessmentLoadingPage';

export default function AppContainerLoading()
{
	return (
		<Routes>
			<Route path="assessment/:assessmentId" element={<AssessmentLoadingPage/>}/>
			<Route path="assessment/:assessmentId/page/:pageIndex" element={<AssessmentLoadingPage/>}/>
		</Routes>
	);
}
