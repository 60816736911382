import React from 'react';
import { Box, TextFieldProps as MUITextFieldProps } from '@mui/material';

import { IValidator, IValidatorResult } from '../../models/IValidator';
import DateHelper from '../../plugins/DateHelper';

import InputBase from './InputBase';
import TextField from './TextField';

interface IProps 
{
	onDateChange(date: string): void;
	validator?: IValidator;
	onValidatorResultChanged?(validatorResult: IValidatorResult): void;
	answerValue?: any[];
	outerLabel?: string;
	helpText?: string;
	disabled?: boolean;
}

export default function DateField(props: MUITextFieldProps & IProps) 
{
	const [maxDay, setMaxDay] = React.useState<number>(31);
	const [day, setDay] = React.useState<string>('');
	const [month, setMonth] = React.useState<string>('');
	const [year, setYear] = React.useState<string>('');

	React.useEffect(() => 
	{
		//Date value is of format yyyy-mm-dd
		const dateParts = (props.value as string).split('-');
		setYear(dateParts[0]);
		setMonth(dateParts[1]);
		setDay(dateParts[2]);

		const intMonth = parseInt(dateParts[1], 10);
		const intYear = parseInt(dateParts[0], 10);
		setMaxDay(DateHelper.getMaxDay(intMonth, intYear));
	}, []);

	React.useEffect(() => 
	{
		if (props.value == '') 
		{
			setYear('');
			setMonth('');
			setDay('');
		}
	}, [props.value]);

	const getCountryCode = () => 
	{
		interface ITimeZones 
		{
			[key: string]: string
		}

		const timeZones: ITimeZones = {
			'America/Adak': 'US',
			'America/Anchorage': 'US',
			'America/Boise': 'US',
			'America/Chicago': 'US',
			'America/Denver': 'US',
			'America/Detroit': 'US',
			'America/Indiana/Indianapolis': 'US',
			'America/Indiana/Knox': 'US',
			'America/Indiana/Marengo': 'US',
			'America/Indiana/Petersburg': 'US',
			'America/Indiana/Tell_City': 'US',
			'America/Indiana/Vevay': 'US',
			'America/Indiana/Vincennes': 'US',
			'America/Indiana/Winamac': 'US',
			'America/Juneau': 'US',
			'America/Kentucky/Louisville': 'US',
			'America/Kentucky/Monticello': 'US',
			'America/Los_Angeles': 'US',
			'America/Menominee': 'US',
			'America/Metlakatla': 'US',
			'America/New_York': 'US',
			'America/Nome': 'US',
			'America/North_Dakota/Beulah': 'US',
			'America/North_Dakota/Center': 'US',
			'America/North_Dakota/New_Salem': 'US',
			'America/Phoenix': 'US',
			'America/Sitka': 'US',
			'America/Yakutat': 'US',
			'Pacific/Honolulu': 'US',
			'US/Alaska': 'US',
			'US/Aleutian': 'US',
			'US/Arizona': 'US',
			'US/Central': 'US',
			'US/East-Indiana': 'US',
			'US/Eastern': 'US',
			'US/Hawaii': 'US',
			'US/Indiana-Starke': 'US',
			'US/Michigan': 'US',
			'US/Mountain': 'US',
			'US/Pacific': 'US',
			'US/Samoa': 'US'
		};

		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (timeZone == '' || !timeZone) return null;

		const countryCode = timeZones[timeZone]

		return countryCode;
	}

	const onChangeDate = (
		strDay: string,
		strMonth: string,
		strYear: string
	) => 
	{
		let date = '';
		if (strDay?.length > 0 || strMonth?.length > 0 || strYear?.length > 0) 
		{
			const intDay = parseInt(strDay, 10);
			const intMonth = parseInt(strMonth, 10);
			const intYear = parseInt(strYear, 10);
			const newMaxDay = DateHelper.getMaxDay(intMonth, intYear);
			setMaxDay(newMaxDay);

			strDay = strDay?.padStart(2, '0');
			strMonth = strMonth?.padStart(2, '0');

			if (strDay == '00' || intDay > newMaxDay) strDay = 'dd';
			if (strMonth == '00') strMonth = 'mm';
			if (intYear < 1000 || intYear > 3000) strYear = 'yyyy';

			date = [
				strYear,
				strMonth,
				strDay
			].join('-');
		}

		props.onDateChange(date);
	};

	const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => 
	{
		let newDay = e.target.value;

		//Trim length
		if (newDay?.length > 2) newDay = newDay.substr(0, 2);

		//Verify value
		let overrideValue = null;
		const intDay = parseInt(newDay, 10);
		if (intDay < 0) overrideValue = 1;
		if (intDay > maxDay) overrideValue = maxDay;

		//Update value if required
		if (isNaN(intDay)) newDay = '';
		if (overrideValue) newDay = overrideValue.toString();

		setDay(newDay);

		onChangeDate(newDay, month, year);
	};

	const onChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) => 
	{
		let newMonth = e.target.value;

		//Trim length
		if (newMonth?.length > 2) newMonth = newMonth.substr(0, 2);

		//Verify value
		let overrideValue = null;
		const intMonth = parseInt(newMonth, 10);
		if (intMonth < 0) overrideValue = 1;
		if (intMonth > 12) overrideValue = 12;

		//Update value if required
		if (isNaN(intMonth)) newMonth = '';
		if (overrideValue) newMonth = overrideValue.toString();

		setMonth(newMonth);

		onChangeDate(day, newMonth, year);
	};

	const onChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => 
	{
		let newYear = e.target.value;

		//Trim length
		if (newYear?.length > 4) newYear = newYear.substr(0, 4);

		//Verify value
		let overrideValue = null;
		const intyear = parseInt(newYear, 10);
		if (newYear.length == 4) 
		{
			if (intyear < 1000) overrideValue = 1000;
			if (intyear > 3000) overrideValue = 3000;
		}

		//Update value if required
		if (isNaN(intyear)) newYear = '';
		if (overrideValue) newYear = overrideValue.toString();

		setYear(newYear);

		onChangeDate(day, month, newYear);
	};

	const onWheel = (e: React.WheelEvent<HTMLDivElement>) => 
	{
		e.currentTarget.querySelector('input')?.blur();
	};

	const getDateFormat = () => 
	{

		switch (getCountryCode()) 
		{
			case 'US':
				return 'MM/DD/YYYY';
			default:
				return 'DD/MM/YYYY';
		}
	}

	const getDatePart = (datePart : string, index : number) => 
	{
		switch (datePart)
		{
			case 'DD':
				return getDayPart(index);
			case 'MM':
				return getMonthPart(index);
			case 'YYYY':
				return getYearPart(index);
		}
	}

	const getDayPart = (index: number) => 
		(
			<Box pr={1} pl={getCountryCode() == 'US' ? 1 : 0} width={1 / 3} key={index}>
				<TextField
					data-testid="day"
					disabled={props.disabled}
					label="Day"
					value={day}
					onBlur={(e) => onBlur(e)}
					onChange={onChangeDay}
					onWheel={onWheel}
					inputProps={{
						step: 1,
						min: 1,
						max: maxDay,
						type: 'number'
					}}
				/>
			</Box>
		)

	const getMonthPart = (index: number) => 
		(
			<Box pr={1} pl={getCountryCode() == 'US' ? 0 : 1} width={1 / 3} key={index}>
				<TextField
					data-testid="month"
					disabled={props.disabled}
					label="Month"
					value={month}
					onBlur={(e) => onBlur(e)}
					onChange={onChangeMonth}
					onWheel={onWheel}
					inputProps={{
						step: 1,
						min: 1,
						max: 12,
						type: 'number'
					}}
				/>
			</Box>
		)

	const getYearPart = (index: number) => 
		(
			<Box pl={1} width={1 / 3} key={index}>
				<TextField
					data-testid="year"
					disabled={props.disabled}
					label="Year"
					value={year}
					onBlur={(e) => onBlur(e)}
					onChange={onChangeYear}
					onWheel={onWheel}
					inputProps={{
						step: 1,
						min: 1000,
						max: 3000,
						type: 'number'
					}}
				/>
			</Box>
		)

	const children = (
		<Box
			display="flex"
			flexGrow={1}
			justifyContent="flex-start"
			flex-wrap="no-wrap"
		>
			{getDateFormat().split('/').map((datePart, index) => getDatePart(datePart, index))}
		</Box>
	);

	const doOnBlur = () =>
		day?.length > 0 && month?.length > 0 && year?.length > 0;

	const onBlur = (
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => 
	{
		if (props.onBlur && doOnBlur()) props.onBlur(e);
	};

	return (
		<InputBase
			outerLabel={props.outerLabel}
			helpText={props.helpText}
			onValidatorResultChanged={props.onValidatorResultChanged}
			validator={props.validator}
			value={props.value}
			answerValue={props.answerValue}
			doOnBlur={doOnBlur}
		>
			{children}
		</InputBase>
	);
}
