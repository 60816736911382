import * as React from 'react';
import { useTheme } from '@davies-group/white-label-ui/theme'
import { LoadingOverlay } from '@davies-group/white-label-ui/ui';
import { CssBaseline, ThemeProvider } from '@mui/material';

import AppContainer from './AppContainer';

export default function AppMain()
{
	const themeData = useTheme();

	const [isLoaded, setIsLoaded] = React.useState(false);

	React.useEffect(() =>
	{
		const isLoaded = themeData.elementStyles !== null && themeData.theme !== null;
		setIsLoaded(isLoaded);
	}, [themeData.elementStyles, themeData.theme]);

	return <>
		<LoadingOverlay isLoading={!isLoaded}/>
		{
			(themeData.theme) &&
				<ThemeProvider theme={themeData.theme}>
					<CssBaseline />
					<AppContainer />
				</ThemeProvider>
		}
	</>
}