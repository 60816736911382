import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { UploadServiceSettingsProvider } from '@davies-group/document-upload-lib';
import { ThemeProvider } from '@davies-group/white-label-ui/theme'
import { StyledEngineProvider } from '@mui/material/styles';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

import AppContainerLoading from './AppContainerLoading';
import  AppMain  from './AppMain';
import { RootState } from './state';

const reactPlugin = new ReactPlugin();
const mapState = (state: RootState) => ({
	assessmentState: state.assessment,
	appState: state.app
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

function App(props: PropsFromRedux)
{
	return (
		<StyledEngineProvider injectFirst>
			{
				props.assessmentState?.assessment ? <ThemeProvider
					apiUrl={window.__appSettings.apiEndpoints.brandingApi}
					brandId={props.assessmentState.assessment.brandId}
					clientId={props.assessmentState.assessment.clientId}>
					<UploadServiceSettingsProvider
						apiUploadEndpoint={`${window.__appSettings.apiEndpoints.documentApi}/upload`}
						apiDeleteEndpoint={`${window.__appSettings.apiEndpoints.documentApi}/delete`}
						uploadInstructionTextEmpty="Please select or drag and drop the file(s) you wish to upload"
						uploadInstructionTextPopulated="Please select or drag and drop any additional file(s) you also wish to upload"
						fileTooLargeText="The file is too large, this file will not be uploaded."
						unknownErrorText="Something went wrong."
						fileCountLimitReachedText="Maximum number of files has been reached.">
						<AppMain />
					</UploadServiceSettingsProvider>
				</ThemeProvider>: <AppContainerLoading />
			}
		</StyledEngineProvider>
	);
}

export default withAITracking(reactPlugin, connector(App));
