import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import IAssessmentElement from '../../models/IAssessmentElement';
import ISchemaItems from '../../models/ISchemaItems';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import TextField from '../inputs/TextField';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function NumberInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const [answerValues, setAnswerValues] = React.useState<string[]>(props.assessmentState.pageAnswers.filter(x => x.id == props.question.id)[0]?.value ?? []);
	const [schemaItems] = React.useState<ISchemaItems | null>(new QuestionManager().getSchemaItems(props.question));

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setAnswerValues([value])
	};

	const onBlur = () =>
	{
		questionManager.updateAnswerValue(props.question.id, answerValues);

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	return <TextField
		onValidatorResultChanged={props.onValidatorResultChanged}
		validator={{
			validatorRules: questionManager.getQuestionValidatorRules(props.question),
			config: props.validatorConfig
		}}
		value={answerValues[0] ?? ''}
		answerValue={answerValues}
		onBlur={onBlur}
		onChange={onChange}
		onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
		inputProps={{
			step: 1,
			min: schemaItems?.minimum,
			max: schemaItems?.maximum,
			type: 'number'
		}} />
}

export default connector(NumberInput)