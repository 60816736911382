import IVehicle from '../models/IVehicle';
import ApiLookupService from '../services/ApiLookupService';

export default class ApiLookupManager
{

	public async getVehicleByVrm(vehicleVrm: string): Promise<IVehicle | null>
	{

		if (!vehicleVrm)
			return null;

		const apiLookupService = new ApiLookupService();

		const vehicle = await apiLookupService.getVehicleByVrm(vehicleVrm)
			.catch(e =>
			{
				console.error(e);
				return null;
			});

		const vehicleDetails = vehicle;

		return vehicleDetails;

	}

}