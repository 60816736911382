import IAnswer from '../../models/IAnswer';
import IAssessment from '../../models/IAssessment';
import IAssessmentElement from '../../models/IAssessmentElement';
import IAssessmentPage from '../../models/IAssessmentPage';

import
{
	AssessmentActionTypes,
	SET_ANSWER,
	SET_ASSESSMENT,
	SET_PAGE,
	UPDATE_ASSESSMENT_ELEMENT
} from './types'

export function setAssessment(payload: IAssessment | null) : AssessmentActionTypes
{
	return {
		type: SET_ASSESSMENT,
		payload: payload
	}
}

export function setPage(payload: IAssessmentPage | null) : AssessmentActionTypes
{
	return {
		type: SET_PAGE,
		payload: payload
	}
}

export function setAnswer(payload: IAnswer) : AssessmentActionTypes
{
	return {
		type: SET_ANSWER,
		payload: payload
	}
}

export function updateAssessmentElement(payload: IAssessmentElement) : AssessmentActionTypes
{
	return {
		type: UPDATE_ASSESSMENT_ELEMENT,
		payload: payload
	}
}