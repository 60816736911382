import { store } from '../index';
import IAnswer from '../models/IAnswer';
import IAssessment from '../models/IAssessment';
import IAssessmentElement, { AssessmentElementType } from '../models/IAssessmentElement';
import IAssessmentPage from '../models/IAssessmentPage';
import ICompletedAssessment from '../models/ICompletedAssessment';
import AssessmentService from '../services/AssessmentService';
import { history } from '../state';
import { setIsLoading } from '../state/app/actions';
import { setAssessment } from '../state/assessment/actions';

import AssessmentLocalStorageManager from './AssessmentLocalStorageManager';
import Common from './Common';
import QuestionManager from './QuestionManager';

export default class AssessmentManager
{
	public async loadAssessment(assessmentId: string): Promise<IAssessment | null>
	{
		if (!assessmentId)
			return null;

		const existingAssessment = store.getState().assessment.assessment;
		if (existingAssessment?.assessmentId === assessmentId)
			return existingAssessment; // already loaded

		const assessmentService = new AssessmentService();

		let assessment = AssessmentLocalStorageManager.getAssessment(assessmentId);
		if(!assessment)
		{
			//no stored assessment - get latest version instead
			assessment = await assessmentService.getAssessment(assessmentId, null)
				.catch(e =>
				{
					console.error(e);
					return null;
				});

			//store assessment for future use
			if (assessment)
				AssessmentLocalStorageManager.setAssessment(assessment);
		}

		if (!assessment)
			return null;

		//Order assessment on Indexes
		assessment.pages = assessment.pages.sort((a, b) => a.index - b.index)
		assessment.pages.forEach(page =>
		{
			page.headerSection = page.headerSection.sort((a, b) => a.index - b.index);
			page.headerSection.forEach(element =>
			{
				element.options = element.options?.sort((a, b) => a.index - b.index) ?? null;
			})
			page.bodySection = page.bodySection.sort((a, b) => a.index - b.index);
			page.bodySection.forEach(element =>
			{
				element.options = element.options?.sort((a, b) => a.index - b.index) ?? null;
			})
			page.footerSection = page.footerSection.sort((a, b) => a.index - b.index);
			page.footerSection.forEach(element =>
			{
				element.options = element.options?.sort((a, b) => a.index - b.index) ?? null;
			})
		})

		store.dispatch(setAssessment(assessment));

		return assessment;
	}

	public async handleMovePage(pageDelta: number): Promise<boolean>
	{
		const assessmentState = store.getState().assessment;
		if (!assessmentState.assessment)
			return false;

		const nextIndex = assessmentState.pageIndex + pageDelta;
		const nextPage = assessmentState.assessment.pages[nextIndex];

		if (nextPage)
		{
			AssessmentLocalStorageManager.setPageIndex(assessmentState.assessment.assessmentId, nextIndex);
			history.push(`/assessment/${assessmentState.assessment.assessmentId}/page/${nextIndex}`);
		}
		else
		{
			if (assessmentState.isLastPage)
			{
				// submit the assessment
				store.dispatch(setIsLoading(true));

				const submitResult = await this.submitAssessment()
					.then(result =>
					{
						store.dispatch(setIsLoading(false));

						if (!result)
							return false;

						return true;
					});

				return submitResult;
			}
		}

		return true;
	}

	private async submitAssessment(): Promise<boolean>
	{
		const assessmentState = store.getState().assessment;
		if (!assessmentState.assessment)
			return false;

		//Only process questions that were shown
		const questionManager = new QuestionManager()
		const allAssessmentQuestions = this.getAllAssessmentQuestions(assessmentState.assessment)
			.filter(q => q.isAlwaysShown || questionManager.showElement(q.showConditionSource ?? '', q.showConditionValue ?? ''))

		const assessmentId = assessmentState.assessment?.assessmentId ?? '';
		const answers = allAssessmentQuestions.map(q =>
		{
			const answer: IAnswer =
            {
            	id: q.id,
            	value: AssessmentLocalStorageManager.getAnswerValues(assessmentId, q.id)
            };

			if (q.type === 'NUMERIC')
				answer.value = answer.value.map((i: any) => Number(i as string)); // number validation need the values as numbers, not strings

			//Policy Questions require additional fields to be sent over in order to do the secondary lookup if multiple policies are returned
			if(q.type === 'POLICY_POSTCODE' || q.type === 'POLICY_DOB' || q.type === 'POLICY_EMAIL')
			{
				const policyNumber = AssessmentLocalStorageManager.getAnswerValues(assessmentId, q.id, 'PolicyNumber')[0];
				const postcode = AssessmentLocalStorageManager.getAnswerValues(assessmentId, q.id, 'Postcode')[0];
				const dateOfBirth = AssessmentLocalStorageManager.getAnswerValues(assessmentId, q.id, 'DateOfBirth')[0];
				const emailAddress = AssessmentLocalStorageManager.getAnswerValues(assessmentId, q.id, 'EmailAddress')[0];

				answer.value = [
					'POLICY_ID:' + (answer.value[0]?.toString() ?? ''),
					'POLICY_NUMBER:' + (policyNumber ?? ''),
					'POLICY_POSTCODE:' + (postcode ?? ''),
					'POLICY_DOB:' + (dateOfBirth ?? ''),
					'POLICY_EMAIL:' + (emailAddress ?? '')
				]
			}

			return answer;
		});

		const completedAssessment: ICompletedAssessment =
        {
        	id: assessmentState.assessment.assessmentId,
        	assessmentVersion: assessmentState.assessment.version,
        	sessionId: Common.newGuid(),
        	answers: answers
        };

		const assessmentService = new AssessmentService();
		const result = await assessmentService.submitAssessment(completedAssessment)
			.catch(e =>
			{
				console.error(e);
				return false;
			});

		if (!result)
			return false;

		this.handleSuccessfulSubmit();

		return true;
	}

	public getAllAssessmentQuestions(assessment: IAssessment) : IAssessmentElement[]
	{
		let allAssessmentQuestions = [] as IAssessmentElement[]
		if (assessment)
		{
			for (let i = 0; i < assessment.pages.length; i++)
			{
				const page = assessment.pages[i];
				allAssessmentQuestions = allAssessmentQuestions.concat(this.getAllPageQuestions(page));
			}
		}
		return allAssessmentQuestions;
	}

	public getAllPageQuestions(page: IAssessmentPage | null) : IAssessmentElement[]
	{
		return (page?.headerSection ?? []).concat(page?.bodySection ?? []).concat(page?.footerSection ?? [])
	}

	public getPolicyId() : string
	{
		const assessmentState = store.getState().assessment;
		if (!assessmentState.assessment)
			return '0';

		const assessmentId = assessmentState.assessment.assessmentId

		const policyIdArray: string[] = []

		this.getAllAssessmentQuestions(assessmentState.assessment).forEach(question => 
		{
			if(this.isPolicyQuestion(question.type))
				policyIdArray.push(AssessmentLocalStorageManager.getAnswerValues(assessmentId, question.id)[0] ?? '0')
		})

		return policyIdArray[0] ?? '0'
	}

	private isPolicyQuestion(questionType: AssessmentElementType | undefined)
	{
		return (questionType === 'POLICY_POSTCODE' || questionType === 'POLICY_DOB' || questionType === 'POLICY_EMAIL')
	}

	private handleSuccessfulSubmit()
	{
		const assessmentState = store.getState().assessment;
		AssessmentLocalStorageManager.clear(assessmentState.assessment?.assessmentId ?? '');
		history.push('/assessment/completed/success');
	}
}
