import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BackgroundCard, ButtonCTA, ButtonText, PageDivider, ProgressBar } from '@davies-group/white-label-ui/ui';
import { ProgressBarStep } from '@davies-group/white-label-ui/ui/ProgressBar';
import { Alert, Box, Typography } from '@mui/material';

import AssessmentElement from '../components/AssessmentElement';
import ErrorMessage from '../components/ErrorMessage';
import ProgressBarLabel from '../components/ProgressBarLabel';
import IAssessmentElement from '../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../models/IValidator';
import AssessmentLocalStorageManager from '../plugins/AssessmentLocalStorageManager';
import AssessmentManager from '../plugins/AssessmentManager';
import Common from '../plugins/Common';
import QuestionManager from '../plugins/QuestionManager';
import ValidationManager from '../plugins/Validation/ValidationManager';
import { RootState } from '../state';
import { history } from '../state';
import { setIsLoading } from '../state/app/actions';
import { setPage } from '../state/assessment/actions';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment,
	appState: state.app
});

const mapDispatch = {
	setPage: setPage,
	setIsLoading: setIsLoading
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

export type QueryParameters =
	{
		assessmentId: string,
		pageIndex: string
	}

export function AssessmentPage(props: PropsFromRedux)
{
	const urlParams = useParams<QueryParameters>();
	const [pageMovementEnabled, setPageMovementEnabled] = React.useState<boolean>(true);
	const [nextButtonClicked, setNextButtonClicked] = React.useState<boolean>(false);
	const [nextButtonClickId, setNextButtonClickId] = React.useState<string>(Common.newGuid());
	const [pageIsValid, setPageIsValid] = React.useState<boolean>(false);
	const [showValidationError, setShowValidationError] = React.useState<boolean>(false);
	const [hasError, setHasError] = React.useState<boolean>(false);
	const [validatorResults, setValidatorResults] = React.useState<IValidatorResult[]>([]);
	const [validatorConfig, setValidatorConfig] = React.useState<IValidatorConfig>(
		{
			hideErrors: true,
			validationId: Common.newGuid()
		});
	const [myRef] = React.useState<React.RefObject<any>>(React.createRef());

	React.useEffect(() =>
	{
		props.setIsLoading(true)

		const storedPageIndex = AssessmentLocalStorageManager.getPageIndex(urlParams.assessmentId ?? '');
		const requestPageIndex = parseInt(urlParams.pageIndex ?? '');
		//Cannot navigate forward through the journey via the URL alone
		if (isNaN(requestPageIndex) || requestPageIndex > storedPageIndex)
			history.push(`/assessment/${urlParams.assessmentId}/page/${storedPageIndex}`);
		else
		{
			new AssessmentManager()
				.loadAssessment(urlParams.assessmentId ?? '')
				.then(assessment =>
				{
					if (!assessment)
					{
						console.log(`Assessment ${urlParams.assessmentId} failed to load`);

						setHasError(true)

						return;
					}

					console.log(`Assessment ${urlParams.assessmentId} loaded`);

					if (myRef?.current)
						myRef.current.scrollIntoView()

					const currentPage = assessment.pages[requestPageIndex];
					props.setPage(currentPage);
				})
				.then(() =>
				{
					setHasError(false)
					setPageMovementEnabled(true)
					setNextButtonClicked(false)
					setPageIsValid(false)
					setShowValidationError(false)
					setValidatorResults([])
					setValidatorConfig({
						hideErrors: true,
						validationId: Common.newGuid()
					})

					props.setIsLoading(false)
				});
		}

	}, [urlParams.pageIndex]);

	React.useEffect(() =>
	{
		//If waiting for Actions to complete then disable Page Movement
		if (nextButtonClicked && !props.assessmentState.pageActionsComplete && pageIsValid)
			setPageMovementEnabled(false)

		//Only advance to next page once validation and actions are completed
		if (nextButtonClicked && props.assessmentState.pageActionsComplete && pageIsValid)
			onMoveForward();
	}, [
		nextButtonClickId,
		props.assessmentState.pageActionsComplete,
		pageIsValid
	]);

	const onMoveForward = () =>
	{
		new AssessmentManager().handleMovePage(1)
			.then(result => onPageMoved(result));
	}

	const onMoveBackward = () =>
		new AssessmentManager().handleMovePage(-1)
			.then(result => onPageMoved(result));

	const onPageMoved = (result: boolean) =>
	{
		setHasError(!result)
	}

	const onBackClick = () =>
	{
		onMoveBackward();
	};

	const onForwardClick = () =>
	{
		const pageIsValid = isPageValid();
		setShowValidationError(!pageIsValid)
		setPageIsValid(pageIsValid)
		setNextButtonClickId(Common.newGuid())
		setNextButtonClicked(true)
	};

	const onSaveClick = () =>
	{
		//TODO
	};

	const isPageValid = () =>
	{
		const validationManager = new ValidationManager();
		const failedValidations = validationManager.getFailedValidatorResults(validatorResults);

		setValidatorConfig({
			...validatorConfig,
			hideErrors: false
		})

		return failedValidations.length == 0;
	}

	const onValidatorResultChanged = (validatorResult: IValidatorResult): void =>
	{
		setValidatorResults((prevResults) => (new ValidationManager().addOrUpdateValidatorResult([...prevResults], validatorResult)))
	}

	const onResetValidation = (parentElement: IAssessmentElement | null): void =>
	{
		//Reset all validation
		if (parentElement == null)
		{
			setValidatorResults([])
			setValidatorConfig({
				...validatorConfig,
				validationId: Common.newGuid()
			})
		}
		//Remove and rerun child elements
		else
		{
			const validationsToReset = new QuestionManager().getAllChildElementReferences(parentElement);
			setValidatorResults(validatorResults.filter(result => validationsToReset.indexOf(result.validationFieldGuid) < 0))
			setValidatorConfig({
				...validatorConfig,
				validationFieldsToRun: validationsToReset
			})
		}
	}

	const getValidatorConfig = (element: IAssessmentElement): IValidatorConfig =>
		({
			...validatorConfig,
			validationFieldGuidOverride: element.referenceId
		})

	return (
		<BackgroundCard>
			{/* Ref used for scrolling on page move */}
			<div ref={myRef} />

			{hasError &&
				<ErrorMessage />}

			{(!hasError && props.assessmentState.page) &&
				<>
					<Box p={4} justifyContent="center">
						<Box pb={2} width={1} display="flex" alignItems="flex-start">
							<Box>
								<Typography align="left" variant="h4">
									<strong>{props.assessmentState.page.name}</strong>
								</Typography>
							</Box>

							<Box display="flex" flexGrow="1" />

							{/* SAVE Button Hidden until Dashboard is availble */}
							<Box hidden={true}>
								<ButtonText
									onClick={onSaveClick}>
									Save
								</ButtonText>
							</Box>
						</Box>

						{
							props.assessmentState.page.showProgressBar &&
							<Box pb={2} width={1}>
								<ProgressBar
									alternativeLabel
									activeStep={props.assessmentState.pageIndex}
									steps={
										(props.assessmentState.assessment?.pages ?? []).map((page, index) =>
											(
												{
													label: (<ProgressBarLabel index={index} pageName={page.name} />)
												} as ProgressBarStep
											)
										)}
								/>
							</Box>
						}
					</Box>

					<PageDivider variant="fullWidth" />

					<Box py={2} px={4} justifyContent="center">
						{
							props.assessmentState.page.headerSection.length > 0 &&
							props.assessmentState.page.headerSection.map(x =>
								(
									<Box key={x.id}>
										<AssessmentElement
											element={x}
											validatorConfig={getValidatorConfig(x)}
											onValidatorResultChanged={onValidatorResultChanged}
											onResetValidation={onResetValidation}
										/>
									</Box>
								))
						}

						{
							props.assessmentState.page.bodySection.length > 0 &&
							props.assessmentState.page.bodySection.map(x =>
								(
									<Box key={x.id}>
										<AssessmentElement
											element={x}
											validatorConfig={getValidatorConfig(x)}
											onValidatorResultChanged={onValidatorResultChanged}
											onResetValidation={onResetValidation}
										/>
									</Box>
								))
						}
					</Box>

					{
						props.assessmentState.page.footerSection.length > 0 &&
						<>
							<PageDivider variant="fullWidth" />
							<Box p={4} justifyContent="center">
								{props.assessmentState.page.footerSection.map(x =>
									(
										<Box key={x.id}>
											<AssessmentElement
												element={x}
												validatorConfig={getValidatorConfig(x)}
												onValidatorResultChanged={onValidatorResultChanged}
												onResetValidation={onResetValidation}
											/>
										</Box>
									))}
							</Box>
						</>
					}

					{
						showValidationError &&
						<Box pb={1} px={4} width={1} display="flex" alignItems="center" justifyContent="flex-end">
							<Alert onClose={() => setShowValidationError(false)} severity="error">Please answer all questions before proceeding</Alert>
						</Box>
					}
					<Box pb={2} px={4} width={1} display="flex" alignItems="center" justifyContent="flex-end">
						<Box data-testid={`Previous_${props.assessmentState.pageIndex}`} pr={2}>
							{
								(!props.assessmentState.isFirstPage) &&
								<ButtonText
									onClick={onBackClick}
									disabled={!pageMovementEnabled}>
									Previous Page
								</ButtonText>
							}
						</Box>

						<Box data-testid={`Next_${props.assessmentState.pageIndex}`} width={1 / 3}>
							<ButtonCTA
								fullWidth={true}
								size="large"
								onClick={onForwardClick}
								disabled={!pageMovementEnabled}>
								{(props.assessmentState.isLastPage) ? <>Finish</> : <>Next</>}
							</ButtonCTA>
						</Box>
					</Box>
				</>
			}
		</BackgroundCard>
	)
}

export default connector(AssessmentPage);
