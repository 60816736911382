import IAssessment from '../models/IAssessment';
import IStoredAssessment from '../models/IStoredAssessment';

import Common from './Common';

export default class AssessmentLocalStorageManager
{
	private static readonly LOCAL_STORAGE_KEY_ASSESSMENT = 'assessment';

	public static getAssessmentKey(assessmentId: string) : string
	{
		return this.LOCAL_STORAGE_KEY_ASSESSMENT + assessmentId
	}

	public static getStoredAssessment(assessmentId: string) : IStoredAssessment | null
	{
		const storedAssessmentString = localStorage.getItem(this.getAssessmentKey(assessmentId));
		return storedAssessmentString ? JSON.parse(storedAssessmentString) as IStoredAssessment : null;
	}

	public static setStoredAssessment(storedAssessment: IStoredAssessment)
	{
		localStorage.setItem(this.getAssessmentKey(storedAssessment.assessment.assessmentId), JSON.stringify(storedAssessment));
	}

	public static getAssessment(assessmentId: string) : IAssessment | null
	{
		const storedAssessment = this.getStoredAssessment(assessmentId);
		return storedAssessment?.assessment ?? null;
	}

	public static setAssessment(assessment: IAssessment)
	{
		const existingStoredAssessment = this.getStoredAssessment(assessment.assessmentId);
		const updatedStoredAssessment = {
			...existingStoredAssessment,
			assessment: assessment
		} as IStoredAssessment;
		this.setStoredAssessment(updatedStoredAssessment);
	}

	public static getPageIndex(assessmentId: string): number
	{
		const storedAssessment = this.getStoredAssessment(assessmentId);
		return storedAssessment?.currentPageIndex ?? 0;
	}

	public static setPageIndex(assessmentId: string, pageIndex: number)
	{
		const existingStoredAssessment = this.getStoredAssessment(assessmentId);
		const updatedStoredAssessment = {
			...existingStoredAssessment,
			currentPageIndex: pageIndex
		} as IStoredAssessment;
		this.setStoredAssessment(updatedStoredAssessment);
	}

	public static getAnswerKey(questionId: string, fieldId?: string): string
	{
		let key = questionId;
		if(fieldId)
			key = key + '|' + fieldId;

		return key;
	}

	public static getAnswerValues(assessmentId: string, questionId: string, fieldId?: string): string[]
	{
		const key = this.getAnswerKey(questionId, fieldId);
		const storedAssessment = this.getStoredAssessment(assessmentId);
		return storedAssessment?.answers?.find(a => a.id == key)?.value.filter((i) => !Common.isNullOrWhiteSpace(i)) ?? [];
	}

	public static setAnswerValues(assessmentId: string, questionId: string, values: string[], fieldId?: string): string[]
	{
		const key = this.getAnswerKey(questionId, fieldId);
		values = values.filter((i) => !Common.isNullOrWhiteSpace(i));

		const existingStoredAssessment = this.getStoredAssessment(assessmentId);

		const updatedStoredAssessment = {...existingStoredAssessment } as IStoredAssessment;

		const existingAnswer = existingStoredAssessment?.answers?.find(a => a.id == key);
		if(existingAnswer)
		{
			updatedStoredAssessment.answers = updatedStoredAssessment.answers.map(a => a.id == key ? {
				...a,
				value: values
			} : a);
		}
		else
		{
			updatedStoredAssessment.answers = [
				...updatedStoredAssessment.answers ?? [], {
					id: key,
					value: values
				}
			];
		}

		this.setStoredAssessment(updatedStoredAssessment);

		return values;
	}

	public static clear(assessmentId: string)
	{
		localStorage.removeItem(this.getAssessmentKey(assessmentId));
	}
}
