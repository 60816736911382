import axios, { AxiosResponse } from 'axios'

export default class BankService
{
	public async validateBankDetails(title: string, firstName: string, middleNames: string, lastName: string, accountNumber: string, sortCode: string, addressLine1: string, addressLine2: string, addressLine3: string, town: string, county: string, postcode: string)
	{
		const url = `${window.__appSettings.apiEndpoints.bankApi}/validate-payee`

		const body =
        {
        	AccountHolderNames: [
        		{
        			title: title,
        			forename: firstName,
        			middleNames: middleNames,
        			surname: lastName
        	    }
        	],
        	AccountHolderAddresses: [
        		{
        			addressLine1: addressLine1,
        			addressLine2: addressLine2,
        			addressLine3: addressLine3,
        			town: town,
        			county: county,
        			postCode: postcode
        		}
        	],
        	accountNumber: accountNumber,
        	sortCode: sortCode
        }

		const response = await axios.post<{ isValidAccount: boolean} >(url, body)
		this.checkResponse(url, response)
		return response.data.isValidAccount
	}

	public async validateBankDetailsWithPolicy(assessmentId: string, version: number, questionId: string, policyId: string, accountNumber: string, sortCode: string)
	{
		const url = `${window.__appSettings.apiEndpoints.bankApi}/${assessmentId}/${version}/${questionId}/validate-policyholder/${policyId}`;

		const body =
		{
			accountNumber: accountNumber,
        	sortCode: sortCode
		}

		const response = await axios.post<{ isValidAccount: boolean} >(url, body)
		this.checkResponse(url, response)
		return response.data.isValidAccount
	}

	private checkResponse<T>(url: string, response: AxiosResponse<T>): boolean
	{
		if (response.status >= 200 && response.status <= 299)
		{
			console.info(`Call to ${url} was successful`);
			return true;
		}

		throw new Error(`Failed to call ${url} (Status: ${response.status})`);
	}
}