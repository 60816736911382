import React from 'react';
import { Radio as StyledRadio } from '@davies-group/white-label-ui/ui'
import { FormControlLabel, RadioGroup as MUIRadioGroup, RadioGroupProps as MUIRadioGroupProps} from '@mui/material';

import IOption from '../../models/IOption';
import { IValidator, IValidatorResult } from '../../models/IValidator';

import InputBase from './InputBase';

interface IProps
{
	options: IOption[],
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult) : void,
	answerValue?: any[],
	outerLabel?: string,
	helpText?: string
}

export default function RadioGroup(props: MUIRadioGroupProps & IProps)
{
	const { options, validator, onValidatorResultChanged, answerValue, outerLabel, helpText, ...rest } = props

	const children = (
		<MUIRadioGroup
			{...rest}
			name="value"
			value={props.value ?? ''}
		>
			{
				options.map((option, index) =>
					<FormControlLabel data-testid={option.value} key={index} value={option.value} control={<StyledRadio />} label={option.text} />)
			}
		</MUIRadioGroup>
	)

	return (
		<InputBase
			outerLabel={outerLabel}
			helpText={helpText}
			onValidatorResultChanged={onValidatorResultChanged}
			validator={validator}
			value={props.value}
			answerValue={answerValue}>
			{children}
		</InputBase>
	)
}
