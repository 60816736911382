import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Schema } from 'jsonschema';

import { store } from '../../index';
import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import PolicyManager from '../../plugins/PolicyManager';
import QuestionManager from '../../plugins/QuestionManager';
import { ValidationRuleHelper } from '../../plugins/Validation/ValidationRuleHelper';
import { RootState } from '../../state';
import { updateAssessmentElement } from '../../state/assessment/actions';
import DateField from '../inputs/DateField';
import TextField from '../inputs/TextField';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {
	updateAssessmentElement: updateAssessmentElement
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function PolicyInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const assessmentState = store.getState().assessment;

	const [assessmentId] = React.useState<string>(assessmentState.assessment?.assessmentId ?? '');
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [policyNumber, setPolicyNumber] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'PolicyNumber')[0]);
	const [postcode, setPostcode] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'Postcode')[0]);
	const [dateOfBirth, setDateOfBirth] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'DateOfBirth')[0]);
	const [emailAddress, setEmailAddress] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'EmailAddress')[0]);

	const doPolicyLookup = () =>
	{
		if(policyNumber?.length > 0 && (postcode?.length > 0 || dateOfBirth?.length > 0 || emailAddress?.length > 0))
		{
			props.updateAssessmentElement(
				{
					...props.question,
					actionsCompleted: false
				});

			(async () =>
			{
				const policyId = await new PolicyManager().getPolicyId(props.question.id, policyNumber, postcode, dateOfBirth, emailAddress) ?? 0;
				questionManager.updateAnswerValue(props.question.id, [policyId.toString()]);
			})().then(() =>
			{
				props.updateAssessmentElement(
					{
						...props.question,
						actionsCompleted: true
					});
			});
		}

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	}



	const setAnswerValues = (questionId: string, answerValues: string[], fieldName: string) =>
	{
		AssessmentLocalStorageManager.setAnswerValues(assessmentState.assessment?.assessmentId ?? '', questionId, answerValues, fieldName)
	};

	const onPolicyNumberChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setPolicyNumber(value)
		setAnswerValues(props.question.id,[e.target.value], 'PolicyNumber')
	};

	const onPostCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setPostcode(value)
		setAnswerValues(props.question.id,[e.target.value], 'Postcode')
	};

	const onDateOfBirthChange = (date: string) =>
	{
		setDateOfBirth(date)
		setAnswerValues(props.question.id, [date], 'DateOfBirth')
	};

	const onEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setEmailAddress(value)
		setAnswerValues(props.question.id,[e.target.value], 'EmailAddress')
	};

	return <Box display="flex" width={1} flexWrap="wrap">
		<Box pr={isSmallScreen ? 0 : 2} width={isSmallScreen ? 1 : 3/4}>
			<TextField
				data-testid="policyNumber"
				outerLabel={props.question.fieldLabels?.find(x => x.id == 'POLICY_NUMBER')?.label ?? ''}
				onValidatorResultChanged={props.onValidatorResultChanged}
				validator={{
					validatorRules: [ValidationRuleHelper.required('Answer')],
					config: {
						...props.validatorConfig,
						validationFieldGuidOverride: undefined //remove override to ensure each field is validated uniquely
					}
				}}
				value={policyNumber ?? ''}
				onBlur={doPolicyLookup}
				onChange={onPolicyNumberChange}/>
		</Box>

		{
			props.question.type == 'POLICY_POSTCODE' &&
			<Box pt={isSmallScreen ? 2 : 0} width={isSmallScreen ? 1 : 1/4}>
				<TextField
					data-testid="policyPostcode"
					outerLabel={props.question.fieldLabels?.find(x => x.id == 'POLICY_POSTCODE')?.label ?? ''}
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [ValidationRuleHelper.required('Answer')],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each field is validated uniquely
						}
					}}
					value={postcode ?? ''}
					onBlur={doPolicyLookup}
					onChange={onPostCodeChange}/>
			</Box>
		}

		{
			props.question.type == 'POLICY_DOB' &&
			<Box pt={2} width={isSmallScreen ? 1 : 1/2}>
				<DateField
					data-testid="policyDob"
					outerLabel={props.question.fieldLabels?.find(x => x.id == 'POLICY_DOB')?.label ?? ''}
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [
							ValidationRuleHelper.schema({
								properties: {
									value: {
										type: 'array',
										items: {
											type: 'string',
											format: 'date'
										},
										uniqueItems: true,
										minItems: 1,
										maxItems: 1
									}
								},
								required: ['value']
							} as Schema)
						],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each field is validated uniquely
						}
					}}
					value={dateOfBirth ?? ''}
					onBlur={doPolicyLookup}
					onDateChange={onDateOfBirthChange}/>
			</Box>
		}

		{
			props.question.type == 'POLICY_EMAIL' &&
			<Box pt={2} width={isSmallScreen ? 1 : 1/2}>
				<TextField
					data-testid="policyEmail"
					outerLabel={props.question.fieldLabels?.find(x => x.id == 'POLICY_EMAIL')?.label ?? ''}
					onValidatorResultChanged={props.onValidatorResultChanged}
					validator={{
						validatorRules: [
							ValidationRuleHelper.schema({
								properties: {
									value: {
										type: 'array',
										items: {
											type: 'string',
											maxLength: 5000,
											pattern: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'
										},
										uniqueItems: true,
										minItems: 1,
										maxItems: 1
									}
								},
								required: ['value']
							} as Schema)
						],
						config: {
							...props.validatorConfig,
							validationFieldGuidOverride: undefined //remove override to ensure each field is validated uniquely
						}
					}}
					value={emailAddress ?? ''}
					onBlur={doPolicyLookup}
					onChange={onEmailAddressChange}/>
			</Box>
		}
	</Box>
}

export default connector(PolicyInput)
