import * as React from 'react';
import { ChangeEvent, useEffect,useState } from 'react';
import { connect,ConnectedProps } from 'react-redux';
import { Box, SelectChangeEvent, Typography, useMediaQuery, useTheme } from '@mui/material';

import { store } from '../../index';
import IAssessmentElement, { FieldLabelId } from '../../models/IAssessmentElement';
import IOption from '../../models/IOption';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import AssessmentManager from '../../plugins/AssessmentManager';
import BankManager from '../../plugins/BankManager';
import Common from '../../plugins/Common';
import QuestionManager from '../../plugins/QuestionManager';
import { ValidationRuleHelper } from '../../plugins/Validation/ValidationRuleHelper';
import { RootState } from '../../state';
import { updateAssessmentElement } from '../../state/assessment/actions';
import Buttons from '../inputs/Buttons';
import DropDown from '../inputs/DropDown';
import InputBase from '../inputs/InputBase';
import TextField from '../inputs/TextField';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {
	updateAssessmentElement: updateAssessmentElement
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

interface BaseAnswer
{
	accountNumber: string,
	sortCode: string,
	payOtherPerson: boolean,
	hasBeenValidated: boolean
}

interface OtherPayeeAnswer extends BaseAnswer
{
	title: string,
	firstName: string,
	middleNames: string,
	lastName: string,
	addressLine1: string,
	addressLine2: string,
	addressLine3: string,
	town: string,
	county: string,
	postcode: string,
	isBusinessAccount: boolean,
	businessAccountName: string,
}

type PaymentType = 'POLICY_HOLDER' | 'OTHER_PERSON'
type AccountType = 'PERSONAL' | 'BUSINESS'

export function BankAccountInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const assessmentManager = new AssessmentManager()
	const assessmentState = store.getState().assessment;

	const [assessmentId] = useState<string>(assessmentState.assessment?.assessmentId ?? '');
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const isValidPolicyId = () =>
		(!(/^0$/.test(policyId)))

	const [policyId] = useState<string>(assessmentManager.getPolicyId())
	const [payOtherPayee, setPayOtherPayee] = useState<string>(isValidPolicyId() ? AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'PayOtherPayee')[0] ?? 'false': 'true')
	const [accountType, setAccountType] = useState<AccountType>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'AccountType')[0] as AccountType ?? 'PERSONAL')

	const [title, setTitle] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'Title')[0] ?? '');
	const [firstName, setFirstName] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'FirstName')[0] ?? '');
	const [middleNames, setMiddleNames] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'MiddleNames')[0] ?? '');
	const [lastName, setLastName] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'LastName')[0] ?? '');
	const [businessAccountName, setBusinessAccountName] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'BusinessAccountName')[0] ?? '');

	const [accountNumber, setAccountNumber] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'AccountNumber')[0] ?? '');
	const [accountSortCode, setAccountSortCode] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'AccountSortCode')[0] ?? '');

	const [addressLine1, setAddressLine1] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'AddressLine1')[0] ?? '');
	const [addressLine2, setAddressLine2] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'AddressLine2')[0] ?? '');
	const [addressLine3, setAddressLine3] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'AddressLine3')[0] ?? '');
	const [town, setTown] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'Town')[0] ?? '');
	const [county, setCounty] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'County')[0] ?? '');
	const [postcode, setPostcode] = useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'Postcode')[0] ?? '');

	const [isValidBankDetails, setIsValidBankDetails] = useState<boolean>(false);
	const [bankValidationGuid] = useState<string>(Common.newGuid());
	const [hideError, setHideError] = useState<boolean>(true);

	const [titleGuid] = useState<string>(Common.newGuid())
	const [firstNameGuid] = useState<string>(Common.newGuid())
	const [lastNameGuid] = useState<string>(Common.newGuid())
	const [businessAccountNameGuid] = useState<string>(Common.newGuid())
	const [accountNumberGuid] = useState<string>(Common.newGuid())
	const [accountSortCodeGuid] = useState<string>(Common.newGuid())
	const [addressLine1Guid] = useState<string>(Common.newGuid())
	const [townGuid] = useState<string>(Common.newGuid())
	const [postcodeGuid] = useState<string>(Common.newGuid())

	const validateBankDetails = () =>
	{
		if(isPayingPayee())
			validateBankOtherPerson()
		else
			validateBankPolicyHolder()
	}

	const validateBankOtherPerson = () =>
	{
		//Currently can only validate Personal Accounts
		if(props.question.metadata?.validateOtherPayeeAccount && accountType === 'PERSONAL')
		{
			if (title != '' && firstName != '' && lastName != '' && accountNumber != '' && accountSortCode != '' && addressLine1 != '' && postcode != '' && town != '')
			{
				props.updateAssessmentElement(
					{
						...props.question,
						actionsCompleted: false
					});

				(async () =>
				{
					const isValidDetails = await new BankManager().getValidDetails(title, firstName, middleNames, lastName, accountNumber, accountSortCode, addressLine1, addressLine2, addressLine3, town, county, postcode) ?? false;

					if (isValidDetails)
					{
						const answerValue = buildAnswer(true, 'OTHER_PERSON');
						questionManager.updateAnswerValue(props.question.id, [JSON.stringify(answerValue)]);
					}

					setIsValidBankDetails(isValidDetails);
					setHideError(isValidDetails);
				})().then(() =>
				{
					props.updateAssessmentElement(
						{
							...props.question,
							actionsCompleted: true
						});
				});
			}
		}

		else
		{
			const isValidDetails = true;
			const answerValue = buildAnswer(false, 'OTHER_PERSON')
			questionManager.updateAnswerValue(props.question.id, [JSON.stringify(answerValue)]);
			setHideError(isValidDetails)
			setIsValidBankDetails(isValidDetails)
		}
	}

	const validateBankPolicyHolder = () =>
	{
		if(props.question.metadata?.validatePolicyHolderAccount)
		{
			if(accountNumber != '' && accountSortCode != '')
			{
				props.updateAssessmentElement(
					{
						...props.question,
						actionsCompleted: false
					}
				);

				(async () =>
				{
					const isValidDetails = await new BankManager().getValidDetailsWithPolicyId(props.question.id, policyId, accountNumber, accountSortCode) ?? false;

					setHideError(isValidDetails)
					setIsValidBankDetails(isValidDetails)

					if (isValidDetails)
					{
						const answerValue = buildAnswer(true, 'POLICY_HOLDER');
						questionManager.updateAnswerValue(props.question.id, [JSON.stringify(answerValue)]);
					}
				})().then(() =>
				{
					props.updateAssessmentElement(
						{
							...props.question,actionsCompleted: true
						}
					)
				})
			}
		}

		else
		{
			const isValidDetails = true;
			const answerValue = buildAnswer(false, 'POLICY_HOLDER')
			questionManager.updateAnswerValue(props.question.id, [JSON.stringify(answerValue)]);
			setHideError(isValidDetails)
			setIsValidBankDetails(isValidDetails)
		}
	}

	const buildAnswer = (hasBeenValidated: boolean, paymentType: PaymentType): OtherPayeeAnswer | BaseAnswer =>
	{
		switch(paymentType)
		{
			case 'POLICY_HOLDER':
				return {accountNumber: accountNumber, sortCode: accountSortCode, hasBeenValidated: hasBeenValidated, payOtherPerson: false} as BaseAnswer
			case 'OTHER_PERSON':
				return {
					title: title,
					firstName: firstName,
					middleNames: middleNames,
					lastName: lastName,
					accountNumber: accountNumber,
					sortCode: accountSortCode,
					addressLine1: addressLine1,
					addressLine2: addressLine2,
					addressLine3: addressLine3,
					postcode: postcode,
					town: town,
					county: county,
					hasBeenValidated: hasBeenValidated,
					payOtherPerson: true,
					isBusinessAccount: accountType === 'BUSINESS',
					businessAccountName: businessAccountName
				} as OtherPayeeAnswer
		}
	}

	const setAnswerValues = (questionId: string, answerValues: string[], fieldName: string) =>
		AssessmentLocalStorageManager.setAnswerValues(assessmentId, questionId, answerValues, fieldName);

	const isOptionSelected = (optionValue: string): boolean =>
		payOtherPayee == optionValue;

	const onPaymentOptionClick = (value: string) =>
	{
		if(value != payOtherPayee)
			clearAnswers()

		if(props.onResetValidation)
			props.onResetValidation(null)

		setPayOtherPayee(value)
		setAnswerValues(props.question.id, [value], 'PayOtherPayee')
	}

	const onAccountTypeOptionClick = (value: string) =>
	{
		const newAccountType = value as AccountType;
		switch(newAccountType)
		{
			case 'PERSONAL':
				clearBusinessAccountHolderDetails();
				break;
			case 'BUSINESS':
				clearAccountHolderDetails();
				break;
		}

		if(props.onResetValidation)
			props.onResetValidation(null)

		setAccountType(newAccountType)
		setAnswerValues(props.question.id, [value], 'AccountType')
	}

	const clearBusinessAccountHolderDetails = () =>
	{
		setBusinessAccountName('')
		setAnswerValues(props.question.id, [''], 'BusinessAccountName');
	}

	const clearAccountHolderDetails = () =>
	{
		setTitle('')
		setAnswerValues(props.question.id, [''], 'Title');

		setFirstName('')
		setAnswerValues(props.question.id, [''], 'FirstName');

		setMiddleNames('')
		setAnswerValues(props.question.id, [''], 'MiddleNames');

		setLastName('')
		setAnswerValues(props.question.id, [''], 'LastName');
	}

	const clearAnswers = () =>
	{
		clearAccountHolderDetails();
		clearBusinessAccountHolderDetails();

		setAccountType('PERSONAL')
		setAnswerValues(props.question.id, [''], 'AccountType');

		setAccountNumber('')
		setAnswerValues(props.question.id, [''], 'AccountNumber');

		setAccountSortCode('')
		setAnswerValues(props.question.id, [''], 'AccountSortCode');

		setAddressLine1('')
		setAnswerValues(props.question.id, [''], 'AddressLine1');

		setAddressLine2('')
		setAnswerValues(props.question.id, [''], 'AddressLine2');

		setAddressLine3('')
		setAnswerValues(props.question.id, [''], 'AddressLine3');

		setPostcode('')
		setAnswerValues(props.question.id, [''], 'Postcode');

		setCounty('');
		setAnswerValues(props.question.id, [''], 'County');

		setTown('')
		setAnswerValues(props.question.id, [''], 'Town');
	}

	const onTitleChange = (event: SelectChangeEvent<unknown>) =>
	{
		const value = event.target.value as string;

		setTitle(value);
		setAnswerValues(props.question.id, [value], 'Title');
	}

	const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setFirstName(value);
		setAnswerValues(props.question.id, [value], 'FirstName');
	}

	const onMiddleNamesChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setMiddleNames(value);
		setAnswerValues(props.question.id, [value], 'MiddleNames')
	}

	const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setLastName(value);
		setAnswerValues(props.question.id, [value], 'LastName');
	}

	const onBusinessAccountNameChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setBusinessAccountName(value);
		setAnswerValues(props.question.id, [value], 'BusinessAccountName');
	}

	const onAccountNumberChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		const isNumber = ((/[0-9]/.test(value.charAt(value.length - 1))) || value == '');

		if (isNumber)
		{
			setAccountNumber(value);
			setAnswerValues(props.question.id, [value], 'AccountNumber');
		}

	}

	const onAccountSortCodeChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		let value = event.target.value;

		const sortCodeRegEx = /(^$)|(^\d{2}-\d{2}-\d{1,2}$)|(^\d{2}-\d{2}-$)|(^\d{2}-\d{1,2}$)|(^\d{2}-$)|(^\d{1,6}$)/
		const isValidSortCode = sortCodeRegEx.test(value);

		if (value.length < accountSortCode.length && isValidSortCode)
		{
			if (accountSortCode.endsWith('-')) value = value.substring(0, value.length - 1)

			setAccountSortCode(value);
			setAnswerValues(props.question.id, [value], 'AccountSortCode');
		}

		if (isValidSortCode)
		{
			let formattedValue = '';

			value = value.replace(/-/g, '')

			for (let i = 0; i < value.length; i++)
				formattedValue += ((i + 1) % 2 == 0 && i > 0 && i < 5) ? (value[i] + '-') : value[i];

			setAccountSortCode(formattedValue);
			setAnswerValues(props.question.id, [formattedValue], 'AccountSortCode');
		}
	}

	const onAddressLine1Change = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setAddressLine1(value);
		setAnswerValues(props.question.id, [value], 'AddressLine1');
	}

	const onAddressLine2Change = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setAddressLine2(value);
		setAnswerValues(props.question.id, [value], 'AddressLine2');
	}

	const onAddressLine3Change = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setAddressLine3(value);
		setAnswerValues(props.question.id, [value], 'AddressLine3');
	}

	const onTownChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setTown(value);
		setAnswerValues(props.question.id, [value], 'Town');
	}

	const onCountyChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setCounty(value);
		setAnswerValues(props.question.id, [value], 'County');
	}

	const onPostcodeChange = (event: ChangeEvent<HTMLInputElement>) =>
	{
		const value = event.target.value;

		setPostcode(value);
		setAnswerValues(props.question.id, [value], 'Postcode');
	}

	const getOuterLabel = (labelId: FieldLabelId) =>
		props.question.fieldLabels?.find(label => label.id == labelId)?.label ?? '';

	const isPayingPayee = () => payOtherPayee == 'true'

	const buildButtonsOptions = (): IOption[] =>
		(
			[{text: getOuterLabel('PAY_POLICY_HOLDER_OPTION'), value: 'false', index: 0, disabled: !isValidPolicyId()}, {text: getOuterLabel('PAY_OTHER_PERSON_OPTION'), value: 'true', index: 1}]
		)

  	const buildAccountTypeOptions = (): IOption[] =>
		(
			[{text: getOuterLabel('PERSONAL_ACCOUNT'), value: 'PERSONAL', index: 0}, {text: getOuterLabel('BUSINESS_ACCOUNT'), value: 'BUSINESS',index: 1}]
		)

	const buildDropdownOptions = (): IOption[] =>
		(
			[
				{text: 'Mr', value: 'Mr', index: 0},
				{text: 'Mrs', value: 'Mrs', index: 1},
				{text: 'Master', value: 'Master', index: 2},
				{text: 'Miss', value: 'Miss', index: 3},
				{text: 'Ms', value: 'Ms', index: 4},
				{text: 'Mx', value: 'Mx', index: 5},
				{text: 'Doctor', value: 'Doctor', index: 6},
				{text: 'Professor', value: 'Professor', index: 7},
				{text: 'Executor', value: 'Executor', index: 8},
				{text: 'Lord', value: 'Lord', index: 9},
				{text: 'Lady', value: 'Lady', index: 10},
				{text: 'Sir', value: 'Sir', index: 11},
				{text: 'Madam', value: 'Madam', index: 12},
				{text: 'Reverand', value: 'Reverand', index: 13},
				{text: 'Rabbi', value: 'Rabbi', index: 14},
				{text: 'Father', value: 'Father', index: 15},
				{text: 'Brother', value: 'Brother', index: 16},
				{text: 'Sister', value: 'Sister', index: 17}
			]
		)

	const getAccountHolderInput = () =>
		 (
			<>
				<Box width={1} pb={1} pt={2}>
					<Typography variant="subtitle1">{getOuterLabel('SUB_ACCOUNT_HOLDER')}</Typography>
				</Box>
				<Box width={1} pb={1}>
					<Buttons
						isAlternate={false}
						value={accountType}
						options={buildAccountTypeOptions()}
						onOptionClick={onAccountTypeOptionClick}
						isOptionSelected={(optionValue: string): boolean => accountType == optionValue}
						outerLabel={getOuterLabel('ACCOUNT_TYPE_SELECT')} />
				</Box>
				{accountType == 'PERSONAL' &&
        <>
        	<Box width={isSmallScreen ? 1 : 1 / 3} pb={1} pr={isSmallScreen ? 0 : 1}>
        		<DropDown
        			data-testid="accountHolderTitle"
        			outerLabel={getOuterLabel('ACCOUNT_TITLE')}
        			value={title}
        			onChange={onTitleChange}
        			onValidatorResultChanged={props.onValidatorResultChanged}
        			validator={{
        				validatorRules: [ValidationRuleHelper.required(getOuterLabel('ACCOUNT_TITLE'))],
        				config: {
        					...props.validatorConfig,
        					validationFieldGuidOverride: titleGuid
        				}
        			}}
        			onBlur={validateBankDetails}
        			autoComplete="honorific-prefix"
        			options={buildDropdownOptions()}
        		/>
        	</Box>
        	<Box width={isSmallScreen ? 1 : 2 / 3} pb={1} pl={isSmallScreen ? 0 : 1}>
        		<TextField
        			data-testid="accountHolderFirstName"
        			outerLabel={getOuterLabel('ACCOUNT_FIRST_NAME')}
        			value={firstName}
        			onChange={onFirstNameChange}
        			onValidatorResultChanged={props.onValidatorResultChanged}
        			validator={{
        				validatorRules: [ValidationRuleHelper.required(getOuterLabel('ACCOUNT_FIRST_NAME'))],
        				config: {
        					...props.validatorConfig,
        					validationFieldGuidOverride: firstNameGuid
        				}
        			}}
        			onBlur={validateBankDetails}
        			autoComplete="given-name"
        		/>
        	</Box>
        	<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pr={isSmallScreen ? 0 : 1}>
        		<TextField
        			data-testid="accountHolderMiddleNames"
        			outerLabel={getOuterLabel('ACCOUNT_OTHER_NAMES')}
        			value={middleNames}
        			onChange={onMiddleNamesChange}
        			onValidatorResultChanged={props.onValidatorResultChanged}
        			onBlur={validateBankDetails}
        			autoComplete="additional-name"
        		/>
        	</Box>
        	<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pl={isSmallScreen ? 0 : 1}>
        		<TextField
        			data-testid="accountHolderLastName"
        			outerLabel={getOuterLabel('ACCOUNT_LAST_NAME')}
        			value={lastName}
        			onChange={onLastNameChange}
        			onValidatorResultChanged={props.onValidatorResultChanged}
        			validator={{
        				validatorRules: [ValidationRuleHelper.required(getOuterLabel('ACCOUNT_LAST_NAME'))],
        				config: {
        					...props.validatorConfig,
        					validationFieldGuidOverride: lastNameGuid
        				}
        			}}
        			onBlur={validateBankDetails}
        			autoComplete="family-name"
        		/>
        	</Box>
        </>
				}
				{accountType == 'BUSINESS' &&
          <Box width={1} pb={1}>
          	<TextField
          		data-testid="businessAccountName"
          		outerLabel={getOuterLabel('BUSINESS_ACCOUNT_NAME')}
          		value={businessAccountName}
          		onChange={onBusinessAccountNameChange}
          		onValidatorResultChanged={props.onValidatorResultChanged}
          		validator={{
          			validatorRules: [ValidationRuleHelper.required(getOuterLabel('BUSINESS_ACCOUNT_NAME'))],
          			config: {
          				...props.validatorConfig,
          				validationFieldGuidOverride: businessAccountNameGuid
          			}
          		}}
          		onBlur={validateBankDetails}
          	/>
          </Box>
				}
			</>
		)

	const getAccountDetailsInput = () =>
		(
			<>
				<Box width={1} pb={1} pt={2}>
					<Typography variant="subtitle1">{getOuterLabel('SUB_ACCOUNT_DETAILS')}</Typography>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pr={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="accountNumber"
						outerLabel={getOuterLabel('ACCOUNT_NUMBER')}
						onValidatorResultChanged={props.onValidatorResultChanged}
						validator={{
							validatorRules: [
								ValidationRuleHelper.required(getOuterLabel('ACCOUNT_NUMBER')),
								ValidationRuleHelper.minLength(getOuterLabel('ACCOUNT_NUMBER'), 8),
								ValidationRuleHelper.maxLength(getOuterLabel('ACCOUNT_NUMBER'), 12)
							],
							config: {
								...props.validatorConfig,
								validationFieldGuidOverride: accountNumberGuid
							}
						}}
						value={accountNumber}
						onChange={onAccountNumberChange}
						onBlur={validateBankDetails}
					/>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pl={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="accountSortCode"
						outerLabel={getOuterLabel('ACCOUNT_SORT_CODE')}
						onValidatorResultChanged={props.onValidatorResultChanged}
						validator={{
							validatorRules: [
								ValidationRuleHelper.required(getOuterLabel('ACCOUNT_SORT_CODE')),
								ValidationRuleHelper.minLength(getOuterLabel('ACCOUNT_SORT_CODE'), 8),
								ValidationRuleHelper.maxLength(getOuterLabel('ACCOUNT_SORT_CODE'), 8)
							],
							config: {
								...props.validatorConfig,
								validationFieldGuidOverride: accountSortCodeGuid
							}
						}}
						value={accountSortCode}
						onChange={onAccountSortCodeChange}
						onBlur={validateBankDetails}
					/>
				</Box>
			</>
		)

	const getAccountAddressInput = () =>
		(
			<>
				<Box width={1} pb={1} pt={2}>
					<Typography variant="subtitle1">{getOuterLabel('SUB_ACCOUNT_ADDRESS')}</Typography>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pr={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="addressLine1"
						outerLabel={getOuterLabel('ACCOUNT_ADDRESS_LINE_1')}
						value={addressLine1}
						onChange={onAddressLine1Change}
						onValidatorResultChanged={props.onValidatorResultChanged}
						validator={{
							validatorRules: [ValidationRuleHelper.required(getOuterLabel('ACCOUNT_ADDRESS_LINE_1'))],
							config: {
								...props.validatorConfig,
								validationFieldGuidOverride: addressLine1Guid
							}
						}}
						onBlur={validateBankDetails}
						autoComplete="billing address-line1"
					/>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pl={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="addressLine2"
						outerLabel={getOuterLabel('ACCOUNT_ADDRESS_LINE_2')}
						value={addressLine2}
						onChange={onAddressLine2Change}
						onValidatorResultChanged={props.onValidatorResultChanged}
						onBlur={validateBankDetails}
						autoComplete="billing address-line2"
					/>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pr={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="addressLine3"
						outerLabel={getOuterLabel('ACCOUNT_ADDRESS_LINE_3')}
						value={addressLine3}
						onChange={onAddressLine3Change}
						onValidatorResultChanged={props.onValidatorResultChanged}
						onBlur={validateBankDetails}
						autoComplete="billing address-line3"
					/>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={1} pl={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="town"
						outerLabel={getOuterLabel('ACCOUNT_TOWN')}
						name="address-level1"
						value={town}
						onChange={onTownChange}
						onValidatorResultChanged={props.onValidatorResultChanged}
						validator={{
							validatorRules: [ValidationRuleHelper.required(getOuterLabel('ACCOUNT_TOWN'))],
							config: {
								...props.validatorConfig,
								validationFieldGuidOverride: townGuid
							}
						}}
						onBlur={validateBankDetails}
						autoComplete="billing address-level1"
					/>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={isSmallScreen ? 1 : 0} pr={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="county"
						outerLabel={getOuterLabel('ACCOUNT_COUNTY')}
						value={county}
						onChange={onCountyChange}
						onValidatorResultChanged={props.onValidatorResultChanged}
						onBlur={validateBankDetails}
					/>
				</Box>
				<Box width={isSmallScreen ? 1 : 1 / 2} pb={isSmallScreen ? 1 : 0} pl={isSmallScreen ? 0 : 1}>
					<TextField
						data-testid="postcode"
						outerLabel={getOuterLabel('ACCOUNT_POSTCODE')}
						value={postcode}
						onChange={onPostcodeChange}
						onValidatorResultChanged={props.onValidatorResultChanged}
						validator={{
							validatorRules: [ValidationRuleHelper.required(getOuterLabel('ACCOUNT_POSTCODE'))],
							config: {
								...props.validatorConfig,
								validationFieldGuidOverride: postcodeGuid
							}
						}}
						onBlur={validateBankDetails}
						autoComplete="billing postal-code"
					/>
				</Box>
			</>
		)

	useEffect(() =>
	{
		setIsValidBankDetails(false);
		setHideError(true);
	}, [
		title,
		firstName,
		middleNames,
		lastName,
		accountNumber,
		accountSortCode,
		addressLine1,
		addressLine2,
		addressLine3,
		town,
		county,
		postcode,
		businessAccountName
	])

	useEffect(() =>
	{
		validateBankDetails()
	}, [])

	return (
		<InputBase
			onValidatorResultChanged={props.onValidatorResultChanged}
			validator={{
				validatorRules: [ValidationRuleHelper.isValidBankAccount()],
				config: {
					...props.validatorConfig,
					validationFieldGuidOverride: bankValidationGuid
				}
			}}
			value={isValidBankDetails}
		>
			<Box display="flex" flexWrap="wrap" width={1}>
				{!isValidPolicyId() &&
				<Box width={1}>
					<Typography variant="subtitle2">{getOuterLabel('SUB_PAYMENT_SELECT')}</Typography>
				</Box>}
				{isValidPolicyId() &&
				<Box width={1} pb={1} pt={2}>
					<Buttons
						isAlternate={false}
						value={payOtherPayee}
						options={buildButtonsOptions()}
						onOptionClick={onPaymentOptionClick}
						isOptionSelected={isOptionSelected}
						outerLabel={getOuterLabel('SUB_PAYMENT_SELECT')} />
				</Box>}
				{isPayingPayee() && getAccountHolderInput()}
				{getAccountDetailsInput()}
				{isPayingPayee() && getAccountAddressInput()}
				{!hideError && <Box width={1}>
					<Typography variant="body1" color="error">{getOuterLabel('INVALID_BANK_DETAILS')}</Typography>
				</Box>}
			</Box>
		</InputBase>
	)
}

export default connector(BankAccountInput)