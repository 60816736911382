import * as React from 'react';
import { Router } from 'react-router-dom';
import { History } from 'history';

interface IProps
{
	children: React.ReactNode;
	history: History;
}

export default function CustomRouter(props: IProps)
{
	const [state, setState] = React.useState({
		action: props.history.action,
		location: props.history.location
	});

	React.useLayoutEffect(() => props.history.listen(setState), [props.history]);

	return <Router basename="" location={state.location} navigationType={state.action} navigator={props.history}>{props.children}</Router>;
}