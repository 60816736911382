export default class DateHelper
{
	public static dateIsValid = (day: number, month: number, year: number) : boolean =>
	{
		if(isNaN(day) || isNaN(month) || isNaN(year))
			return false

		// Check the ranges of month and year
		if(year < 1000 || year > 3000 || month < 1 || month > 12)
			return false;

		const maxDay = DateHelper.getMaxDay(month, year)

		// Check the range of the day
		return day > 0 && day <= maxDay;
	}

	public static getMaxDay = (month: number, year: number) =>
	{
		if(isNaN(month) || isNaN(year) || month < 1 || month > 12)
			return 31

		const monthLength = DateHelper.getMonthLength(year)
		return monthLength[month - 1]
	}

	public static getMonthLength = (year: number) =>
	{
		const monthLength =
		[
			31,
			28,
			31,
			30,
			31,
			30,
			31,
			31,
			30,
			31,
			30,
			31
		];

		// Adjust for leap years
		if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
			monthLength[1] = 29;

		return monthLength;
	}
}
