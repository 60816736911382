// state
export interface IAppState
{
	isLoading: boolean
}

// actions
export const SET_IS_LOADING = 'SET_IS_LOADING'

interface SetIsLoadingAction
{
	type: typeof SET_IS_LOADING
	payload: boolean
}

export type AppActionTypes = SetIsLoadingAction