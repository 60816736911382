import { store } from '../index';
import IAnswer from '../models/IAnswer';
import IAssessmentElement from '../models/IAssessmentElement';
import ISchemaItems from '../models/ISchemaItems';
import { IValidatorRule } from '../models/IValidator';
import { setAnswer } from '../state/assessment/actions';

import { ValidationRuleHelper } from './Validation/ValidationRuleHelper';
import AssessmentLocalStorageManager from './AssessmentLocalStorageManager';
import AssessmentManager from './AssessmentManager';
import Common from './Common';

export default class QuestionManager
{
	public getSchemaItems = (question: IAssessmentElement) : ISchemaItems | null =>
	{
		let schemaItems = null;

		const schemaProperties = question.validationSchema?.properties;
		if (schemaProperties)
			schemaItems = schemaProperties['value']?.items as ISchemaItems;

		if (!schemaItems)
			throw new Error(`Question ${question.id} has a malformed validation schema`);

		return schemaItems;
	}

	public updateAnswerValue = (questionId: string, answerValues: string[]) =>
	{
		answerValues = answerValues.filter((i) => !Common.isNullOrWhiteSpace(i));

		const updatedAnswer ={
			id: questionId,
			value: answerValues
		} as IAnswer

		store.dispatch(setAnswer(updatedAnswer));
	}

	public getQuestionValidatorRules = (question: IAssessmentElement) =>
	{
		const validatorRules = [] as IValidatorRule[];
		if(question.validatorRules)
			validatorRules.push(...question.validatorRules)
		if(question.validationSchema)
			validatorRules.push(ValidationRuleHelper.question(question))

		return validatorRules;
	}

	public showElement(showConditionSource: string, showConditionValue: string): boolean
	{
		const existingAssessment = store.getState().assessment.assessment;
		if(!existingAssessment)
			return false

		const allElements = new AssessmentManager().getAllAssessmentQuestions(existingAssessment);
		const allConditionsAreMet = this.areConditionsMet(existingAssessment.assessmentId, allElements, showConditionSource, showConditionValue)
		return allConditionsAreMet;
	}

	public areConditionsMet(assessmentId: string, allElements: IAssessmentElement[], showConditionSource: string, showConditionValue: string): boolean
	{
		let allConditionsAreMet = true;
		if(showConditionSource?.length > 0 && showConditionValue?.length > 0)
		{
			const parentQuestion = allElements.filter(q => q.referenceId == showConditionSource)[0];
			const answerValues = AssessmentLocalStorageManager.getAnswerValues(assessmentId, parentQuestion.id);
			allConditionsAreMet = false;
			const regexp = new RegExp(showConditionValue)
			if(answerValues.length == 0)
				allConditionsAreMet=regexp.test('')
			else
			{
				for (let i = 0; i < answerValues.length; i++)
				{
					const value = answerValues[i]
					if(regexp.test(value))
					{
						allConditionsAreMet=true
						break;
					}
				}
			}

			//If conditions are met we then traverse up all dependent parents check each is also met
			if(allConditionsAreMet)
				allConditionsAreMet = this.areConditionsMet(assessmentId, allElements, parentQuestion.showConditionSource ?? '', parentQuestion.showConditionValue ?? '')

		}

		return allConditionsAreMet;
	}

	public getAllChildElementReferences(parentElement: IAssessmentElement): string[]
	{
		const existingAssessment = store.getState().assessment.assessment;
		if(!existingAssessment)
			return []

		const allElements = new AssessmentManager().getAllAssessmentQuestions(existingAssessment);
		const allChildReferences = this.getChildren(allElements, parentElement)
		return allChildReferences;
	}

	private getChildren(allElements: IAssessmentElement[], parentElement: IAssessmentElement): string[]
	{
		let children = [] as string[];
		if(parentElement.hasConditionalChildren)
		{
			const childElements = allElements.filter(q => q.showConditionSource == parentElement.referenceId);
			children = children.concat(childElements.map(x => x.referenceId))

			for (let i = 0; i < childElements.length; i++)
				children = children.concat(this.getChildren(allElements, childElements[i]))
		}

		return children;
	}
}
