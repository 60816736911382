import ICountry from '../models/ICountry';
import IOption from '../models/IOption';
import IPet from '../models/IPet';
import IProduct from '../models/IProduct';
import LookupDataService from '../services/LookupDataService';

export default class LookupDataManager
{
	public async getLookupOptions(lookupCategory: string, lookupSubCategory: string, filter: string): Promise<IOption[]>
	{
		let lookupOptions = [] as IOption[]
		const lookupDataService = new LookupDataService();

		switch(lookupCategory.toLowerCase())
		{
			case 'pedigreedogs':
			case 'pedigreecats':
			case 'crossbreeddogs':
			case 'crossbreedcats':
			{
				const items = await lookupDataService.getPets(lookupCategory.toLowerCase(), lookupSubCategory.toLowerCase(), filter)
					.catch(e =>
					{
						console.error(e);
						return [] as IPet[];
					});
				lookupOptions = items.map(x => ({index: x.index, text: x.breed, value: x.breed}) as IOption)
				break
			}
			case 'bicycles':
			case 'browngoods':
			case 'whitegoods':
			case 'cameras':
			case 'camcorders':
			case 'diygardening':
			case 'flooring':
			case 'furniture':
			case 'gaming':
			case 'golf':
			case 'it':
			case 'livingaids':
			case 'mobilephones':
			{
				const items = await lookupDataService.getProducts(lookupCategory.toLowerCase(), lookupSubCategory.toLowerCase(), filter)
					.catch(e =>
					{
						console.error(e);
						return [] as IProduct[];
					});
				lookupOptions = items.map(x => ({index: x.index, text: x.brand + ' ' + x.modelDescription, value: x.brandModelId?.toString() ?? ''}) as IOption)
				break
			}
			case 'countries':
			{
				const items = await lookupDataService.getCountries(lookupCategory.toLowerCase(), filter)
					.catch(e =>
					{
						console.error(e);
						return [] as ICountry[];
					});
				lookupOptions = items.map(x => ({index: x.index, text: x.name, value: x.name}) as IOption)
				break
			}
		}

		return lookupOptions.sort((a, b) => a.index - b.index);
	}
}
