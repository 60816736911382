import { store } from '../index';
import PolicyService from '../services/PolicyService';

export default class PolicyManager
{
	public async getPolicyId(questionId: string, policyNumber: string, postcode?: string, dateOfBirth?: string, emailAddress?: string): Promise<number | null>
	{
		const assessment = store.getState().assessment.assessment;
		if (!assessment)
			return null;

		const policyId = await new PolicyService().getPolicyId(assessment.assessmentId, assessment.version, questionId, policyNumber, postcode, dateOfBirth, emailAddress)
			.catch(e =>
			{
				console.error(e);
				return null;
			});

		if (!policyId)
			return null;

		return policyId;
	}
}
