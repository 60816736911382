import * as React from 'react';
import { connect,ConnectedProps } from 'react-redux';
import { BackgroundCard, MessageBox } from '@davies-group/white-label-ui/ui'
import { Box, Typography } from '@mui/material';

import AssessmentElement from '../components/AssessmentElement';
import { RootState } from '../state';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
})

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

export function CompletedAssessmentPage(props: PropsFromRedux)
{
	const submissionPage = props.assessmentState.assessment?.submissionPage;

	const showCustomTitle = () => 
		submissionPage !== undefined 
		&& submissionPage.pageTitle !== undefined 
		&& submissionPage.pageTitle !== '';

	const showCustomMessage = () =>
		submissionPage !== undefined 
		&& submissionPage.messageBoxContents !== undefined 
		&& submissionPage.messageBoxContents.length > 0;

	return (
		<BackgroundCard>
			<Box p={4} justifyContent="center">
				<Box pb={2} width={1} display="flex" alignItems="flex-start">
					<Typography align="left" variant="h4">
						<strong>{showCustomTitle() ? submissionPage?.pageTitle : 'Claim Submitted'}</strong>
					</Typography>
				</Box>

				<Box pb={2} width={1}>
					<MessageBox>
						{!showCustomMessage() && 
						(<Typography sx ={{fontWeight: 'bold'}}>Thank you. Your claim has been submitted and we will contact you shortly.</Typography>)}

						{showCustomMessage() &&
						submissionPage?.messageBoxContents.map(x => 
							(
								<Box key={x.id}>
									<AssessmentElement element={x} />
								</Box>
							))}
					</MessageBox>
				</Box>
			</Box>

			{/* Remove section for now */}
			{/* <PageDivider variant="fullWidth"/>

			<Box py={2} px={4} justifyContent="center">
				<Box p={2} width={1} alignItems="flex-start">
					<Box pr={1}>
						<Typography align="left" variant="subtitle1">
							<strong>Claim Status:</strong> Our claims team are currently evaluating your claim. We will be in contact within 1 working day to further progress your claim.
						</Typography>
					</Box>
				</Box>

				<Box width={1} display="flex" alignItems="center" justifyContent="flex-end">
					<Box width={1/3} hidden ={true}>
						<Button
							variant="contained"
							fullWidth={true}
							size="large"
							color="primary">
								Back to dashboard
						</Button>
					</Box>
				</Box>
			</Box> */}
		</BackgroundCard>
	)
}

export default connector(CompletedAssessmentPage);
