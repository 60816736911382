import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import DateField from '../inputs/DateField';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function DateInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const [answerValues, setAnswerValues] = React.useState<string[]>(props.assessmentState.pageAnswers.filter(x => x.id == props.question.id)[0]?.value ?? []);

	const onDateChange = (date: string) =>
	{
		setAnswerValues([date])
		questionManager.updateAnswerValue(props.question.id, [date]);

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	return <DateField
		onValidatorResultChanged={props.onValidatorResultChanged}
		validator={{
			validatorRules: questionManager.getQuestionValidatorRules(props.question),
			config: props.validatorConfig
		}}
		value={(answerValues.length) ? answerValues[0] : ''}
		answerValue={answerValues}
		onDateChange={onDateChange}/>
}

export default connector(DateInput)
