import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import RadioGroup from '../inputs/RadioGroup';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	defaultValue?: string,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function RadioInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const [answerValues, setAnswerValues] = React.useState<string[]>([]);

	React.useEffect(() =>
	{
		const answers = props.assessmentState.pageAnswers.filter(x => x.id == props.question.id)[0]?.value ?? [];
		if(props.defaultValue && answers.length == 0)
			onChange(props.defaultValue)
		else
			setAnswerValues(answers)

	}, [props.defaultValue]);

	const onChange = (value: string) =>
	{
		setAnswerValues([value])
		questionManager.updateAnswerValue(props.question.id, [value]);

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	return <RadioGroup
		onValidatorResultChanged={props.onValidatorResultChanged}
		validator={{
			validatorRules: questionManager.getQuestionValidatorRules(props.question),
			config: props.validatorConfig
		}}
		value={answerValues[0] ?? ''}
		answerValue={answerValues}
		options={props.question.options ?? []}
		onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}/>
}

export default connector(RadioInput)
