import React from 'react';
import { TextField as StyledTextField } from '@davies-group/white-label-ui/ui'
import { TextFieldProps as MUITextFieldProps } from '@mui/material';

import { IValidator, IValidatorResult } from '../../models/IValidator';

import InputBase from './InputBase';

interface IProps
{
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult) : void,
	answerValue?: any[],
	outerLabel?: string,
	helpText?: string
}

export default function TextField(props: MUITextFieldProps & IProps)
{
	const { validator, onValidatorResultChanged, answerValue, outerLabel, helpText, ...rest } = props

	const children = (
		<StyledTextField
			{...rest}
			variant="outlined"
			fullWidth={true}
		/>
	);

	return (
		<InputBase
			outerLabel={outerLabel}
			helpText={helpText}
			onValidatorResultChanged={onValidatorResultChanged}
			validator={validator}
			value={props.value}
			answerValue={answerValue}>
			{children}
		</InputBase>
	)
}
