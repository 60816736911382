import React from 'react';
import { OptionButton as StyledOptionButton } from '@davies-group/white-label-ui/ui'
import { Box, ButtonProps } from '@mui/material';

import IOption from '../../models/IOption';
import { IValidator, IValidatorResult } from '../../models/IValidator';

import InputBase from './InputBase'

interface IProps
{
	isAlternate: boolean,
	options: IOption[],
	onOptionClick(optionValue: string): void,
	isOptionSelected(optionValue: string): boolean,
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult) : void,
	answerValue?: any[],
	outerLabel?: string,
	helpText?: string
}

export default function Buttons(props: ButtonProps & IProps)
{
	const { isAlternate, options, validator, onValidatorResultChanged, isOptionSelected, onOptionClick, answerValue, outerLabel, helpText, ...rest } = props

	const children = <Box display="flex" flexGrow={1} justifyContent="flex-start" flexWrap="wrap">
		{
			options.map((option, index) =>
				<Box m={1} key={index}>
					<StyledOptionButton
						{...rest}
						data-testid={option.value}
						isAlternate={isAlternate}
						fullWidth={true}
						size="large"
						key={index}
						onClick={() => onOptionClick(option.value)}
						isSelected={isOptionSelected(option.value)}
						disabled={option.disabled ?? false}
					>
						{option.text}
					</StyledOptionButton>
				</Box>)
		}
	</Box>

	return (
		<InputBase
			outerLabel={outerLabel}
			helpText={helpText}
			onValidatorResultChanged={onValidatorResultChanged}
			validator={validator}
			value={props.value}
			answerValue={answerValue}>
			{children}
		</InputBase>
	)
}
