import { connectRouter, routerMiddleware as createRouterMiddleware,RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { applyMiddleware,createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { appReducer } from './app/reducers';
import { IAppState } from './app/types';
import { assessmentReducer } from './assessment/reducers';
import { IAssessmentState } from './assessment/types';

export const history = createBrowserHistory();

export const routerMiddleware = createRouterMiddleware(history);

interface IRootState
{
	router: RouterState,
	assessment: IAssessmentState,
	app: IAppState
}

const rootReducer = combineReducers<IRootState>(
	{
		router: connectRouter(history),
		assessment: assessmentReducer,
		app: appReducer
	});


export function configureStore(initialState?: RootState)
{
	// configure middlewares
	const middlewares = [routerMiddleware];

	// compose enhancers
	const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

	// create store
	return createStore(rootReducer, initialState!, enhancer);
}

export type RootState = ReturnType<typeof rootReducer>
