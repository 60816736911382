import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Footer,Header, Layout } from '@davies-group/white-label-ui/ui';

import AssessmentPage from './containers/AssessmentPage';
import CompletedAssessmentPage from './containers/CompletedAssessmentPage';
import { RootState } from './state';

const mapState = (state: RootState) => ({
	appState: state.app
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

export function AppContainer(props: PropsFromRedux)
{
	return (
		<Layout
			header={<Header elevation={0} enableLogoHref={false}/>}
			footer={<Footer
				appName={window.__appSettings.applicationName}
				appVersion={window.__appSettings.applicationVersion}
				appCopyright={window.__appSettings.applicationCopyright}/>}
			isLoading={props.appState.isLoading}
			maxWidth="md">
			<Routes>
				<Route path="assessment/completed/success" element={<CompletedAssessmentPage/>}/>
				<Route path="assessment/:assessmentId" element={<AssessmentPage/>}/>
				<Route path="assessment/:assessmentId/page/:pageIndex" element={<AssessmentPage/>}/>
			</Routes>
		</Layout>
	);
}

export default connector(AppContainer);
