import axios, { AxiosResponse } from 'axios';

import ICountry from '../models/ICountry';
import IPet from '../models/IPet';
import IProduct from '../models/IProduct';

export default class LookupDataService
{
	public getPets(category: string, subCategory: string, filter: string): Promise<IPet[]>
	{
		const url = `${window.__appSettings.apiEndpoints.lookupApi}/${category}?subcategory=${encodeURIComponent(subCategory)}&filter=${encodeURIComponent(filter)}`;

		return axios.get<IPet[]>(url)
			.then(response =>
			{
				this.checkResponse(url, response);
				return response.data;
			});
	}

	public getProducts(category: string, subCategory: string, filter: string): Promise<IProduct[]>
	{
		const url = `${window.__appSettings.apiEndpoints.lookupApi}/${category}?subcategory=${encodeURIComponent(subCategory)}&filter=${encodeURIComponent(filter)}`;

		return axios.get<IProduct[]>(url)
			.then(response =>
			{
				this.checkResponse(url, response);
				return response.data;
			});
	}

	public getCountries(category: string, filter: string): Promise<ICountry[]>
	{
		const url = `${window.__appSettings.apiEndpoints.lookupApi}/${category}?filter=${encodeURIComponent(filter)}`;

		return axios.get<ICountry[]>(url)
			.then(response =>
			{
				this.checkResponse(url, response);
				return response.data;
			});
	}

	private checkResponse<T>(url: string, response: AxiosResponse<T>): boolean
	{
		if (response.status >= 200 && response.status <= 299)
		{
			console.info(`Call to ${url} was successful`);
			return true;
		}

		throw new Error(`Failed to call ${url} (Status: ${response.status})`);
	}
}
