import * as React from 'react';
import { connect,ConnectedProps } from 'react-redux';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment }  from 'moment';

import { store } from '../..';
import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import InputBase from '../inputs/InputBase';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function TimeInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const assessmentState = store.getState().assessment;
	
	const [assessmentId] = React.useState<string>(assessmentState.assessment?.assessmentId ?? '');

	const [localTime, setLocalTime] = React.useState<Moment | null>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'localTime')[0] ? moment.parseZone('2023-01-01T'.concat(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'localTime')[0])) : null);
	const [utcTime, setUtcTime] = React.useState<string>(AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id)[0] ?? '')

	const setAnswerValues = (questionId: string, answerValues: string[], fieldName: string) =>
		AssessmentLocalStorageManager.setAnswerValues(assessmentId, questionId, answerValues, fieldName);

	const onChange = (value: Moment | null) => 
	{
		setLocalTime(value);
		
		if (value?.isValid())
		{
			const timeWithOffset = value.format('HH:mmZ');
			setAnswerValues(props.question.id, [timeWithOffset], 'localTime');

			const utcTime = moment.utc(value).format('HH:mm');
			setUtcTime(utcTime);
			questionManager.updateAnswerValue(props.question.id, [utcTime]);
		}

		if (!value)
		{
			setUtcTime('');
			questionManager.updateAnswerValue(props.question.id, []);
		}
	}

	const children = (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<TimePicker value={localTime} onChange={onChange}/>
		</LocalizationProvider>
	)

	return(
		<InputBase 
			onValidatorResultChanged={props.onValidatorResultChanged} 
			validator={{
				validatorRules: questionManager.getQuestionValidatorRules(props.question),
				config: props.validatorConfig
			}}
			value={utcTime}
			answerValue={[utcTime]}>
			{children}
		</InputBase>

	)
}

export default connector(TimeInput) 
