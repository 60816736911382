import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOverlay } from '@davies-group/white-label-ui/ui';

import AssessmentManager from '../plugins/AssessmentManager';

export type QueryParameters =
{
	assessmentId: string
}

export default function AssessmentLoadingPage()
{
	const urlParams = useParams<QueryParameters>();

	React.useEffect(() =>
	{
		new AssessmentManager()
			.loadAssessment(urlParams.assessmentId ?? '')
	}, []);

	return <LoadingOverlay isLoading={true}/>
}