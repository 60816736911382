import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppInsightsProvider } from '@davies-group/error-handling';

import App from './App';
import CustomRouter from './CustomRouter';
import { configureStore, history } from './state';

declare global
{
	interface Window
	{
		__appSettings: any
	}
}

export const store = configureStore();
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<CustomRouter history={history}>
				<AppInsightsProvider instrumentationKey={window.__appSettings.applicationInsightsInstrumentationKey} history={history}>
					<App />
				</AppInsightsProvider>
			</CustomRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
