import IAnswer from '../../models/IAnswer';
import IAssessmentElement from '../../models/IAssessmentElement';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import AssessmentManager from '../../plugins/AssessmentManager';

import
{
	AssessmentActionTypes,
	IAssessmentState,
	SET_ANSWER,
	SET_ASSESSMENT,
	SET_PAGE,
	UPDATE_ASSESSMENT_ELEMENT
} from './types'

const initialState: IAssessmentState =
{
	assessment: null,
	page: null,
	pageActionsComplete: true,
	pageIndex: -1,
	totalPages: 0,
	isFirstPage: true,
	isLastPage: true,
	pageAnswers: []
};

export function assessmentReducer(
	state = initialState,
	action: AssessmentActionTypes
): IAssessmentState
{
	switch (action.type)
	{
		case SET_ASSESSMENT:
		{
			const assessment = action.payload;
			return {
				...state,
				assessment: assessment,
				totalPages: assessment?.pages.length ?? 0
			}
		}
		case SET_PAGE:
		{
			const page = action.payload;

			let index = -1;
			const pageAnswers = [] as IAnswer[]
			if (page && state.assessment)
			{
				index = state.assessment.pages.indexOf(page) ?? -1;
				const allElements = new AssessmentManager().getAllAssessmentQuestions(state.assessment);
				processPageSection(allElements, page.headerSection, pageAnswers);
				processPageSection(allElements, page.bodySection, pageAnswers);
				processPageSection(allElements, page.footerSection, pageAnswers);
			}

			AssessmentLocalStorageManager.setPageIndex(state.assessment?.assessmentId ?? '', index);

			return {
				...state,
				page: page,
				pageIndex: index,
				isFirstPage: index === 0,
				isLastPage: index + 1 === state.totalPages,
				pageAnswers: pageAnswers
			};
		}
		case SET_ANSWER:
		{
			const answer = action.payload;
			answer.value = AssessmentLocalStorageManager.setAnswerValues(state.assessment?.assessmentId ?? '', answer.id, answer.value);

			return {
				...state,
				pageAnswers: state.pageAnswers.map(x => x.id == answer.id ? answer : x)
			};
		}
		case UPDATE_ASSESSMENT_ELEMENT:
		{
			if(!state.page)
				return state

			const element = action.payload;

			const page = {
				...state.page,
				headerSection: state.page.headerSection?.map(x => x.id == element.id ? element : x),
				bodySection: state.page.bodySection?.map(x => x.id == element.id ? element : x),
				footerSection: state.page.footerSection?.map(x => x.id == element.id ? element : x)
			};
			const allPageElements = new AssessmentManager().getAllPageQuestions(page);
			const pageActionsAreComplete = allPageElements.filter(x => !x.actionsCompleted).length == 0

			return {
				...state,
				page: page,
				pageActionsComplete: pageActionsAreComplete
			};
		}
		default:
			return state
	}

	function processPageSection(allElements: IAssessmentElement[], sectionElements: IAssessmentElement[], pageAnswers: IAnswer[])
	{
		for (let i = 0; i < sectionElements.length; i++)
		{
			const question = sectionElements[i];

			sectionElements[i].actionsCompleted = true;

			const conditionalChilden = allElements.filter(q => q.showConditionSource == question.referenceId);
			if (conditionalChilden?.length > 0)
				sectionElements[i].hasConditionalChildren = true;

			const answerValues = AssessmentLocalStorageManager.getAnswerValues(state.assessment?.assessmentId ?? '', question.id);
			pageAnswers.push({
				id: question.id,
				value: answerValues
			});
		}
	}
}
