import React from 'react';
import { Info } from '@mui/icons-material';
import { Box, FormControl, FormHelperText, IconButton, Tooltip, Typography } from '@mui/material';

import { IValidator, IValidatorResult } from '../../models/IValidator';
import Common from '../../plugins/Common';
import ValidationManager from '../../plugins/Validation/ValidationManager';

interface IProps
{
	children: JSX.Element,
	value: any,
	validator?: IValidator,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	answerValue?: any[], //Must be supplied if the Validation Rule Type is a Question
	doOnBlur?(): boolean, //Function to determine when onBlur validation should occur
	outerLabel?: string,
	helpText?: string
}

export default function InputBase(props: IProps)
{
	const [errorMessage, setErrorMessage] = React.useState<string>('');
	const [hideError, setHideError] = React.useState<boolean>(props.validator?.config?.hideErrors ?? true);
	const [validationFieldGuid, setValidationFieldGuid] = React.useState<string>('');

	React.useEffect(() =>
	{
		const timeout = setTimeout(() =>
		{
			runValidation();
		}, 911); // Debounce
		return () => clearTimeout(timeout);
	}, [
		props.value,
		props.answerValue,
		props.validator?.config?.validationId
	]);

	React.useEffect(() =>
	{
		if (props.validator?.config?.validationFieldsToRun && props.validator.config.validationFieldsToRun.indexOf(validationFieldGuid) >= 0)
			runValidation()
	}, [props.validator?.config?.validationFieldsToRun]);

	React.useEffect(() =>
	{
		setHideError(props.validator?.config?.hideErrors ?? true)
	}, [props.validator?.config?.hideErrors, props.validator?.config?.validationId]);

	const runValidation = () =>
	{
		if (props.validator)
		{
			const errorMessage = [];
			const validationManager = new ValidationManager();
			const validatorResult = getInitialResult();
			const rules = props.validator.validatorRules;
			for (let i = 0; i < rules.length; i++)
			{
				const rule = rules[i];
				//Question Validation must be performed against the raw AnswerValue
				const validationValue = rule.validationRule == 'QUESTION' ? props.answerValue : props.value;
				const result = validationManager.runValidation(validationValue, rule);

				if (!result.isValid)
					errorMessage.push(result.errorMessageOverride ?? rule.errorMessage);

				validatorResult.validatorRuleResults[i] =
				{
					validationRule: rule.validationRule,
					validationRuleParam: rule.validationRuleParam,
					errorMessage: rule.errorMessage,
					isValid: result.isValid
				}
			}

			if (props.onValidatorResultChanged)
				props.onValidatorResultChanged(validatorResult);

			setErrorMessage(errorMessage.join(', '));
		}
	}

	const getInitialResult = () =>
	{
		const validatorResult = {
			validationFieldGuid: validationFieldGuid,
			validatorRuleResults: [],
			tabIndex: props.validator?.config?.tabIndex
		} as IValidatorResult;

		if (validatorResult.validationFieldGuid == '')
		{
			validatorResult.validationFieldGuid = props.validator?.config?.validationFieldGuidOverride ?? Common.newGuid();
			setValidationFieldGuid(validatorResult.validationFieldGuid);
		}
		return validatorResult;
	}

	const onBlur = () =>
	{
		const doOnBlur = props.doOnBlur ? props.doOnBlur() : true;
		if (doOnBlur)
			setHideError(false)
	}

	return (
		<FormControl error={!hideError && errorMessage != ''} fullWidth={true}>
			<Box display="flex" alignItems="center">
				{
					props.outerLabel &&
					<Box pb={1}>
						<Typography variant="subtitle2">{props.outerLabel}</Typography>
					</Box>
				}
				{
					props.helpText &&
					<>
						<Box flexGrow={1} />
						<Box pb={1}>
							<Tooltip title={props.helpText}>
								<IconButton size="small" aria-label="info">
									<Info />
								</IconButton>
							</Tooltip>
						</Box>
					</>
				}
			</Box>
			<Box display="flex" flexGrow={1} justifyContent="flex-start" onBlur={() => onBlur()}>
				{props.children}
			</Box>
			<FormHelperText>{hideError ? '' : errorMessage}</FormHelperText>
		</FormControl>
	)
}
