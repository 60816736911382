import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';

import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import DropDown from '../inputs/DropDown';


const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function DropdownInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const [answerValues, setAnswerValues] = React.useState<string[]>(props.assessmentState.pageAnswers.filter(x => x.id == props.question.id)[0]?.value ?? []);

	const onChange = (event: SelectChangeEvent<unknown>) =>
	{
		const value = event.target.value as string;
		setAnswerValues([value])
		questionManager.updateAnswerValue(props.question.id, [value]);

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	return <DropDown
		onValidatorResultChanged={props.onValidatorResultChanged}
		validator={{
			validatorRules: questionManager.getQuestionValidatorRules(props.question),
			config: props.validatorConfig
		}}
		value={answerValues[0] ?? ''}
		answerValue={answerValues}
		options={props.question.options ?? []}
		onChange={onChange}>
	</DropDown>
}

export default connector(DropdownInput)
