import axios, { AxiosResponse } from 'axios';

import IAssessment from '../models/IAssessment';
import ICompletedAssessment from '../models/ICompletedAssessment';

export default class AssessmentService
{
	public getAssessment(assessmentId: string, version: number | null): Promise<IAssessment>
	{
		let url = `${window.__appSettings.apiEndpoints.assessmentApi}/${assessmentId}`;
		if(version)
			url = `${url}/${version}`;
		return axios.get<IAssessment>(url)
			.then(response =>
			{
				this.checkResponse(url, response);
				return response.data;
			});
	}

	public submitAssessment(completedAssessment:ICompletedAssessment): Promise<boolean>
	{
		const url = window.__appSettings.apiEndpoints.submitApi;

		return axios.post(url, completedAssessment)
			.then(response =>
			{
				this.checkResponse(url, response);
				return true;
			});
	}

	private checkResponse<T>(url: string, response: AxiosResponse<T>): boolean
	{
		if (response.status >= 200 && response.status <= 299)
		{
			console.info(`Call to ${url} was successful`);
			return true;
		}

		throw new Error(`Failed to call ${url} (Status: ${response.status})`);
	}
}
