import
{
	AppActionTypes,
	IAppState,
	SET_IS_LOADING
} from './types'

const initialState: IAppState = {
	isLoading: false
}

export function appReducer(
	state = initialState,
	action: AppActionTypes
): IAppState
{
	switch (action.type)
	{
		case SET_IS_LOADING:
			return {
				...state,
				isLoading: action.payload
			}
		default:
			return state
	}
}