import { store } from '../index';
import BankService from '../services/BankService';

export default class BankManager
{
	public async getValidDetails(title: string, firstName: string, middleNames: string, lastName: string, accountNumber: string, sortCode: string, addressLine1: string, addressLine2: string, addressLine3: string, town: string, county: string, postcode: string)
	{
		const validResult = await new BankService().validateBankDetails(title, firstName, middleNames, lastName, accountNumber, sortCode, addressLine1, addressLine2, addressLine3, town, county, postcode)
			.catch(error =>
			{
				console.error(error);
				return false;
			});

		return validResult
	}

	public async getValidDetailsWithPolicyId(questionId: string, policyId: string, accountNumber: string, sortCode: string)
	{
		const assessment = store.getState().assessment.assessment
		if (!assessment)
			return false;
		const {assessmentId, version} = assessment

		const validResult = await new BankService().validateBankDetailsWithPolicy(assessmentId, version, questionId, policyId, accountNumber, sortCode)
			.catch(error =>
			{
				console.error(error);
				return false;
			});

		return validResult
	}
}