import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { store } from '../../index';
import IAssessmentElement from '../../models/IAssessmentElement';
import IOption from '../../models/IOption';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import AssessmentLocalStorageManager from '../../plugins/AssessmentLocalStorageManager';
import LookupDataManager from '../../plugins/LookupDataManager';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import SmartSearch from '../inputs/SmartSearch';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function LookupDataInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const assessmentState = store.getState().assessment;

	const [assessmentId] = React.useState<string>(assessmentState.assessment?.assessmentId ?? '');
	const [answerValues, setAnswerValues] = React.useState<string[]>([]);
	const [value, setValue] = React.useState<IOption | null>(null)
	const [options, setOptions] = React.useState<IOption[]>([])
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(() =>
	{
		const existingAnswers = assessmentState.pageAnswers.filter(x => x.id == props.question.id)[0]?.value ?? [];
		setAnswerValues(existingAnswers)

		const existingAnswerValue = existingAnswers[0] ?? ''
		//Use the stored filter value to retrieve the initial options
		const lookupFilter = AssessmentLocalStorageManager.getAnswerValues(assessmentId, props.question.id, 'Filter')

		updateOptions(lookupFilter[0], existingAnswerValue)
	}, []);

	const onValueChange = (newValue: IOption | null) =>
	{
		const newAnswerValue = newValue?.value ?? '';
		setAnswerValues([newAnswerValue])
		questionManager.updateAnswerValue(props.question.id, [newAnswerValue]);
		setValue(newValue)

		//Save the displayed text of the selected option, this is then used for loading the option on a page refresh
		AssessmentLocalStorageManager.setAnswerValues(assessmentId, props.question.id, [newValue?.text ?? ''], 'Filter')

		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	const updateOptions = (filter: string, answerValue?: string) =>
	{
		(async () =>
		{
			if(props.question.metadata?.lookupDataCategory)
			{
				//We only call the API when a filter is provided
				if((filter?.length ?? 0) == 0)
					setOptions([])

				else
				{
					setIsLoading(true)
					await new LookupDataManager().getLookupOptions(props.question.metadata.lookupDataCategory, props.question.metadata.lookupDataSubCategory ?? '', filter)
						.then(result =>
						{
							setOptions(result)
							if(answerValue)
							{
								//If Answer Value is provided, set the correct Option from the returned values
								const answer = result.find(x => x.value == answerValue) ?? null
								setValue(answer)
							}
							setIsLoading(false)
						})
				}
			}
		})();
	}

	return (
		<SmartSearch
			onValidatorResultChanged={props.onValidatorResultChanged}
			validator={{
				validatorRules: questionManager.getQuestionValidatorRules(props.question),
				config: props.validatorConfig
			}}
			value={value}
			answerValue={answerValues}
			options={options}
			onValueChange={onValueChange}
			onInputChange={updateOptions}
			isLoading={isLoading}/>

	)
}

export default connector(LookupDataInput)
