export default class Common
{
	public static isNullOrWhiteSpace(value: string)
	{
		return value === null || value?.toString().match(/^ *$/) !== null;
	}

	public static newGuid()
	{
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c)
		{
			// eslint-disable-next-line
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	public static escapeHtml(unescapedHtml: string)
	{
		return unescapedHtml
			?.replace(/&/g, '&amp;')
			?.replace(/</g, '&lt;')
			?.replace(/>/g, '&gt;')
			?.replace(/"/g, '&quot;')
			?.replace(/'/g, '&#039;');
	}

	public static unescapeHtml(escapedHtml: string)
	{
		return escapedHtml
			?.replace(/&amp;/g, '&')
			?.replace(/&lt;/g, '<')
			?.replace(/&gt;/g, '>')
			?.replace(/&quot;/g, '"')
			?.replace(/&#039;/g, '\'');
	}
}
