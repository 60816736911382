import * as React from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';

export default function ErrorMessage()
{
	return (
		<Box p={2} my={5}>
			<Alert severity="error">
				<AlertTitle>Something went wrong.</AlertTitle>
  				Please refresh the page and try again.
			</Alert>
		</Box>
	)
}
