import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidatorConfig, IValidatorResult } from '../../models/IValidator';
import QuestionManager from '../../plugins/QuestionManager';
import { RootState } from '../../state';
import TextField from '../inputs/TextField';

const mapState = (state: RootState) => ({
	assessmentState: state.assessment
});

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface IProps extends PropsFromRedux
{
	question: IAssessmentElement,
	onValidatorResultChanged?(validatorResult: IValidatorResult): void,
	onResetValidation?(parentElement: IAssessmentElement | null): void,
	validatorConfig?: IValidatorConfig
}

export function TextAreaInput(props: IProps)
{
	const questionManager = new QuestionManager();
	const [answerValues, setAnswerValues] = React.useState<string[]>(props.assessmentState.pageAnswers.filter(x => x.id == props.question.id)[0]?.value ?? []);
	const [hasFocus, setHasFocus] = React.useState<boolean>(false);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		const value = e.target.value
		setAnswerValues([value])

		//If value has been updated without focus e.g. auto filled then update stored value
		//If not then wait until onBlur for better performance
		if(!hasFocus)
			updateStoredAnswer([value])
	};

	const onBlur = () =>
	{
		updateStoredAnswer(answerValues)
		setHasFocus(false)
	};

	const updateStoredAnswer = (values: string[]) =>
	{
		questionManager.updateAnswerValue(props.question.id, values);
		if(props.onResetValidation && props.question.hasConditionalChildren)
			props.onResetValidation(props.question);
	};

	return <TextField
		onValidatorResultChanged={props.onValidatorResultChanged}
		validator={{
			validatorRules: questionManager.getQuestionValidatorRules(props.question),
			config: props.validatorConfig
		}}
		value={(answerValues.length) ? answerValues[0] : ''}
		answerValue={answerValues}
		onBlur={onBlur}
		onFocus={() => setHasFocus(true)}
		onChange={onChange}
		multiline
		rows={5}/>
}

export default connector(TextAreaInput)
