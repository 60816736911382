import { Schema, validate, ValidatorResult } from 'jsonschema';
import moment from 'moment';

import IAssessmentElement from '../../models/IAssessmentElement';
import { IValidationResult, IValidatorResult, IValidatorRule } from '../../models/IValidator';
import Common from '../Common';

export default class ValidationManager
{
	public addOrUpdateValidatorResult = (validatorResults: IValidatorResult[], validatorResult: IValidatorResult): IValidatorResult[]  =>
	{
		let updatedValidatorResults = [] as IValidatorResult[];
		if(validatorResults.map(x => x.validationFieldGuid).indexOf(validatorResult.validationFieldGuid)>= 0)
			updatedValidatorResults = validatorResults.map(x => x.validationFieldGuid == validatorResult.validationFieldGuid ? validatorResult : x);
		else
			updatedValidatorResults= [...validatorResults, validatorResult]

		return updatedValidatorResults;
	}

	public getFailedValidatorResults = (validatorResults: IValidatorResult[]): IValidatorResult[] =>
	{
		const failedValidations = [] as IValidatorResult[];
		for (let i = 0; i < validatorResults.length; i++)
		{
			const failedRules = validatorResults[i].validatorRuleResults.filter(x => !x.isValid);
			if(failedRules.length > 0)
			{
				failedValidations.push(
					{
						validatorRuleResults: failedRules,
						validationFieldGuid: validatorResults[i].validationFieldGuid,
						tabIndex: validatorResults[i].tabIndex
					})
			}
		}

		return failedValidations;
	}

	public runValidation = (value: any, validatorRule: IValidatorRule) : IValidationResult =>
	{
		let result = false;
		let errorMessageOverride = null;
		switch(validatorRule.validationRule)
		{
			case 'REQUIRED':
			{
				result = !this.isEmpty(value)
				break
			}
			case 'MAX_STRING_LENGTH':
			{
				const maxLength = parseInt(validatorRule.validationRuleParam ?? '')
				result = this.isString(value) && value.length <= maxLength
				break
			}
			case 'MIN_STRING_LENGTH':
			{
				const minLength = parseInt(validatorRule.validationRuleParam ?? '')
				result = this.isString(value) && value.length >= minLength
				break
			}
			case 'IS_NUMBER':
			{
				result = this.isNumber(value)
				break
			}
			case 'MAX_NUMBER':
			{
				const maxNumber = parseInt(validatorRule.validationRuleParam ?? '')
				result = this.isNumber(value) && value <= maxNumber
				break
			}
			case 'MIN_NUMBER':
			{
				const minNumber = parseInt(validatorRule.validationRuleParam ?? '')
				result = this.isNumber(value) && value >= minNumber
				break
			}
			case 'MAX_DATE':
			{
				if(this.isEmpty(value))
					result = true
				else
					result = moment(value).isSameOrBefore(moment(validatorRule.validationRuleParam), 'day')
				break
			}
			case 'MIN_DATE':
			{
				if(this.isEmpty(value))
					result = true
				else
					result = moment(value).isSameOrAfter(moment(validatorRule.validationRuleParam), 'day')
				break
			}
			case 'FUTURE_DATE':
			{
				if(this.isEmpty(value))
					result = true
				else
				{
					const offsetDays = parseInt(validatorRule.validationRuleParam ?? '0')
					result = moment(value).isSameOrAfter(moment().add(offsetDays, 'days'), 'day')
				}
				break
			}
			case 'PAST_DATE':
			{
				if(this.isEmpty(value))
					result = true
				else
				{
					const offsetDays = parseInt(validatorRule.validationRuleParam ?? '0')
					result = moment(value).isSameOrBefore(moment().add(offsetDays, 'days'), 'day')
				}
				break
			}
			case 'IS_EMAIL':
			{
				result = this.isEmail(value)
				break
			}
			case 'QUESTION':
			{
				const question = JSON.parse(validatorRule.validationRuleParam ?? '') as IAssessmentElement
				const validationAnswer =
				{
					value: value.filter((i: any) => !Common.isNullOrWhiteSpace(i as string)) as any[]
				};

				if (question.type === 'NUMERIC')
					validationAnswer.value = validationAnswer.value.map((i: any) => Number(i as string)); // number validation need the values as numbers, not strings

				const validationResult = validate(validationAnswer, question.validationSchema);

				result= validationResult.valid;
				errorMessageOverride = this.getSchemaErrorMessage(validationResult);
				break
			}
			case 'SCHEMA':
			{
				const validationSchema = JSON.parse(validatorRule.validationRuleParam ?? '') as Schema

				const validationResult = validate({value:[value]}, validationSchema);

				result = validationResult.valid;
				errorMessageOverride = this.getSchemaErrorMessage(validationResult);
				break
			}
			case 'IS_GUID':
			{
				result = this.isGuid(value)
				break
			}
			case 'IS_VALID_BANK_ACCOUNT':
			{
				result = value
				break
			}
		}

		return {isValid: result, errorMessageOverride: errorMessageOverride} as IValidationResult;
	}

	private isExisty = (value: any) => value !== null && value !== undefined

	private isEmpty = (value: any) =>
	{
		if (value instanceof Array)
			return value.length === 0;

		return value === '' || !this.isExisty(value);
	};

	private isGuid = (value: any) => this.matchRegExp(value, /^(((?=.*}$){)|((?!.*}$)))((?!.*-.*)|(?=(.*[-].*){4}))[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}?[}]?$/)

	private isString = (value: any) => this.isEmpty(value) || typeof value === 'string' || value instanceof String

	private isNumber = (value: any) => !isNaN(value)

	private isEmail = (value: any) => this.matchRegExp(value, /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

	private matchRegExp = (value: any, regexp: any) =>
	{
		const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
		return (this.isEmpty(value) || validationRegexp.test(value));
	}

	//ToDo integrate configurable Error Messages
	private getSchemaErrorMessage = (validatorResult: ValidatorResult) =>
	 {
		let error = validatorResult.errors.join(', ');
		if (error.indexOf('does not match pattern') >= 0)
		{
			//Override regex error messages
			error = 'Answer format is not valid';
		}
		else if (error == 'instance.value does not meet minimum length of 1')
			error = 'Answer is required';

		else
		{
			error = error.replace('instance.value[0]', 'Answer');
			error = error.replace('instance.value', 'Answer');
			error = error.replace('is not one of enum values', 'must be one of the following values');
		}
		return error;
	}
}